import { MediaDocument } from '../media-document.model';

/**
 * Common data across all sites
 */
export class Landing {
  headerDisplayed: 'image' | 'video'
  headerImages: MediaDocument[];
  headerVideo: string;
  promotionalVideo: string;

  /**
   * Construct landing from a partial landing object
   * @param landing A Landing like object
   */
  constructor(landing?: Partial<Landing>) {
    this.headerDisplayed = landing?.headerDisplayed ?? 'image';
    this.headerImages = landing?.headerImages?.map(i => new MediaDocument(i)) ?? [];
    this.headerVideo = landing?.headerVideo ?? '';
    this.promotionalVideo = landing?.promotionalVideo ?? '';
  }
}
