export enum CartItemType {
  Standard = 'standard', // Standard product (ex. Buy a PASS 2H for me)
  Gift = 'gift', // Offer a product to another customer (ex. Buy a PASS 2H for my friend)
}

export abstract class CartItem {
  readonly type: CartItemType;
  product: string; // The product id (Firestore id)

  protected constructor(cartItem?: Partial<CartItem>) {
    this.type = CartItemType.Standard;
    this.product = cartItem?.product ?? '';
  }
}

export enum GiftFormat {
  eTicket = 'eTicket',
  mail = 'mail'
}

export class GiftCartItem extends CartItem {
  override type = CartItemType.Gift
  sentToMe: boolean;
  format: GiftFormat;
  from: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  zip: string;
  city: string;
  message: string;
  shipDate: string;

  constructor(giftCartItem?: Partial<GiftCartItem>) {
    super(giftCartItem);
    this.sentToMe = giftCartItem?.sentToMe ?? true;
    this.format = giftCartItem?.format ?? GiftFormat.eTicket;
    this.from = giftCartItem?.from ?? '';
    this.firstName = giftCartItem?.firstName ?? '';
    this.lastName = giftCartItem?.lastName ?? '';
    this.email = giftCartItem?.email ?? '';
    this.address = giftCartItem?.address ?? '';
    this.zip = giftCartItem?.zip ?? '';
    this.city = giftCartItem?.city ?? '';
    this.message = giftCartItem?.message ?? '';
    this.shipDate = giftCartItem?.shipDate ?? new Date().toISOString().slice(0, 10);
  }
}

export type SpaBooking = {
  date: string;
  products: { erpId: number, start: string }[];
  created: string
}

export class StandardCartItem extends CartItem {
  override type = CartItemType.Standard
  spaBooking: SpaBooking | null;

  constructor(standardCartItem: Partial<StandardCartItem>) {
    super(standardCartItem);
    this.spaBooking = standardCartItem?.spaBooking ?? null;
  }
}
