import { MediaDocument } from '../media-document.model';

export class PressItem {
  date: string;
  title: string;
  url: string;
  pdf: MediaDocument;

  constructor(item?: Partial<PressItem>) {
    this.date = item?.date ?? '';
    this.title = item?.title ?? '';
    this.url = item?.url ?? '';
    this.pdf = new MediaDocument(item?.pdf);
  }
}

/**
 * Common data across all sites
 */
export class Press {
  title: string;
  password: string;
  pressKit: MediaDocument;
  images: MediaDocument[];
  pressReleases: PressItem[];
  inThePress: PressItem[];

  /**
   * Construct press from a partial press object
   * @param press A Press like object
   */
  constructor(press?: Partial<Press>) {
    this.title = press?.title ?? '';
    this.password = press?.password ?? '';
    this.pressKit = new MediaDocument(press?.pressKit);
    this.images = press?.images?.map(i => new MediaDocument(i)) ?? [];
    this.pressReleases = press?.pressReleases?.map(pr => new PressItem(pr)) ?? [];
    this.inThePress = press?.inThePress?.map(pr => new PressItem(pr)) ?? [];
  }
}
