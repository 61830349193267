import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PendingTasks, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DataService } from '../../core/data.service';
import { AuthService } from './auth.service';
import { CategoryService } from './category.service';
import { CenterService } from './center.service';
import { EventService } from './event.service';
import { FaqService } from './faq.service';
import { GlobalService } from './globals.service';
import { PageNotificationService } from './page-notification.service';
import { PageService } from './page.service';
import { ProductService } from './product.service';
import { SiteService } from './site.service';
import { UniverseService } from './universe.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  platformId = inject(PLATFORM_ID);
  dataService = inject(DataService);
  pageNotificationService = inject(PageNotificationService);
  faqService = inject(FaqService);
  pageService = inject(PageService);
  globalService = inject(GlobalService);
  centerService = inject(CenterService);
  universeService = inject(UniverseService);
  productService = inject(ProductService);
  categoryService = inject(CategoryService);
  eventService = inject(EventService);
  authService = inject(AuthService);
  siteService = inject(SiteService);
  taskService = inject(PendingTasks)
  loader: Promise<void> | null = null;

  /**
   * Init data
   * @returns The promise
   */
  async initData(): Promise<void> {
    if (!this.loader) {
      const cleanUp = this.taskService.add();
      this.loader = ((isPlatformServer(this.platformId) || Capacitor.isNativePlatform()) ?
        this.dataService.loadData() : Promise.resolve())
        .then(() => Promise.all([
          this.pageNotificationService.init(),
          this.faqService.init(),
          this.pageService.init(),
          this.globalService.init(),
          this.centerService.init(),
          this.universeService.init(),
          this.productService.init(),
          this.categoryService.init(),
          this.eventService.init(),
          this.authService.init(),
        ]))
        .then(() => {
          cleanUp()
        });
    }
    return this.loader;
  }
}
