import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Route, Routes, UrlSegment } from '@angular/router';
import { TranslateService } from '@tolgee/ngx';
import { Roles } from '../../../core';
import { Page } from './shared/model/page.model';
import { AnalyticsService } from './shared/services/analytics.service';
import { AuthService } from './shared/services/auth.service';
import { CartService, } from './shared/services/cart.service';
import { HeaderService } from './shared/services/header.service';
import { LoaderService } from './shared/services/loader.service';
import { PageService } from './shared/services/page.service';
import { SiteService } from './shared/services/site.service';

export const hasRole: (role: Roles, exact?: boolean) => () => boolean
  = (role: Roles, exact: boolean = false) => () => inject(AuthService).hasRole(role, exact);

export const header: (type: 'full' | 'plain' | 'minimal') => () => true
  = type => () => {
    inject(HeaderService).type.set(type);
    return true;
  };

export const displaySearch: (withSearch: boolean) => () => true
  = withSearch => () => {
    inject(HeaderService).search.set(withSearch);
    return true;
  };

export const metaDescription: (siteService: SiteService, translateService: TranslateService, meta: Meta,
                               target: string
) => void =
  (siteService, translateService, meta, target: string) => {
    const center = siteService.center();
    const centerName = center ? `Les Cents Ciels ${center.name} ${center.city}` : '';
    const keyName = 'header.meta.description';
    let content = translateService.instant(keyName, { center: centerName });
    if (target) {
      const newContent = translateService.instant(keyName + `.${target}`);
      content = newContent || content;
    }
    meta.updateTag({ name: 'description', content });
    meta.updateTag({ name: 'og:description', content });
    meta.updateTag({ name: 'twitter:description', content });
  };

export const dataLoader: (route: Route, segments: UrlSegment[]) => Promise<void> =
  (route: Route, segments: UrlSegment[]) => {
    const siteService = inject(SiteService);
    const translateService = inject(TranslateService);
    const meta = inject(Meta);
    return inject(LoaderService).initData()
      .then(() => metaDescription(
        siteService,
        translateService,
        meta,
        segments[0]?.toString() ?? ''
      ));
  };

export const isPage: () => (route: Route, segments: UrlSegment[]) => boolean
  = () => (_: Route, segments) => inject(PageService).pages().some(p => p.id === segments[segments.length - 1].path);

export const isCenter: () => boolean = () => !!inject(SiteService).center();

export const cartLoaded: () => Promise<true> = async () => {
  if (isPlatformServer(inject(PLATFORM_ID))) {
    return true;
  }
  const cartService = inject(CartService);
  const analyticsService = inject(AnalyticsService);
  while (!cartService.lastCartSync()) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
  analyticsService.viewCart(cartService.cart().items.map(cartItem => ({ cartItem })))
  return true;
}

/**
 * Resolves the page for a given route.
 * @param route - The current route snapshot.
 * @returns The page
 */
export function pageResolver(route: ActivatedRouteSnapshot): Page {
  return inject(PageService).pages().find(p => p.id === route.paramMap.get('page')) ?? new Page();
}

export const routes: Routes = [
  {
    path: '',
    canMatch: [dataLoader, displaySearch(false)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        title: 'header.meta.subtitle',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        canMatch: [header('full')]
      },
      {
        path: 'help',
        title: 'faq',
        loadComponent: () => import('./faq/faq.component').then(m => m.FaqComponent),
        canMatch: [header('minimal')]
      },
      {
        path: 'univers',
        title: 'the100cielsUniverse',
        loadComponent: () => import('./universes/universes.component').then(m => m.UniversesComponent),
        canMatch: [header('plain')]
      },
      {
        path: 'cse',
        title: 'cse',
        loadComponent: () => import('./cse/cse.component').then(m => m.CseComponent),
        canMatch: [header('plain')]
      },
      {
        path: 'press',
        title: 'pressArea',
        loadComponent: () => import('./press/press.component').then(m => m.PressComponent),
        canMatch: [header('minimal')]
      },
      {
        path: 'careers',
        title: 'careers',
        loadComponent: () => import('./career/career.component')
          .then(m => m.CareerComponent),
        canMatch: [header('minimal')]
      },
      {
        path: 'account',
        canMatch: [header('minimal')],
        loadChildren: () => import('./account/account.routes').then(m => m.routes)
      },
      {
        path: 'auth',
        canMatch: [header('minimal')],
        title: 'header.meta.subtitle',
        loadChildren: () => import('./auth/auth.routes').then(m => m.routes),
      },
      {
        path: 'a-propos',
        title: 'aboutUs.title1',
        canMatch: [header('minimal')],
        loadComponent: () => import('./about-us/about-us.component').then(m => m.AboutUsComponent),
      },
      {
        path: ':page',
        loadComponent: () => import('./page/page.component').then(m => m.PageComponent),
        canMatch: [isPage(), header('minimal')],
        resolve: {
          page: pageResolver
        }
      },
      {
        path: '',
        canMatch: [isCenter, displaySearch(true), header('minimal')],
        children: [
          {
            path: 'offers',
            title: 'offers',
            loadComponent: () => import('./products/products.component').then(m => m.ProductsComponent),
          },
          {
            path: 'product',
            loadChildren: () => import('./products/products.route').then(m => m.routes),
          },
          {
            path: 'vouchers',
            title: 'buyVoucher',
            loadComponent: () => import('./gift-certificate/gift-certificate.component')
              .then(m => m.GiftCertificateComponent),
          },
          {
            path: 'checkout',
            title: 'checkout',
            loadComponent: () => import('./checkout/checkout.component').then(m => m.CheckoutComponent),
            canMatch: [cartLoaded],
          },
        ]
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];
