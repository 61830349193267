import { computed, effect, inject, Injectable, makeStateKey, signal, TransferState, untracked, } from '@angular/core';
import { Firestore, getDocsFromCache, namedQuery, } from '@angular/fire/firestore';
import { TranslateService } from '@tolgee/ngx';
import { ProductType } from '../../../../../core/model/product.model';
import { ProductGroup } from '../model/product-group.model';
import { Product } from '../model/product.model';
import { CenterService } from './center.service';
import { SelectionService } from './selection.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Product Service
 */
export class ProductService {
  private readonly productsStateKey = makeStateKey<Product[]>('PRODUCTS');
  private readonly productGroupsStateKey = makeStateKey<ProductGroup[]>('PRODUCTS_GROUPS');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  private selectionService = inject(SelectionService);
  private centerService = inject(CenterService);
  private translateService = inject(TranslateService);
  private _products = signal<Product[]>([]);
  private _activeProducts = computed(() => {
    const products = this._products();

    const now = new Date();
    const utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    return products.filter(p => {
      if (p.from) {
        const [yearStart, monthStart, dayStart] = p.from.split('-');
        const validityStart = Date.UTC(Number(yearStart), Number(monthStart) - 1, Number(dayStart));
        if (validityStart > utcNow) {
          return false;
        }
      }
      if (p.to) {
        const [yearEnd, monthEnd, dayEnd] = p.to.split('-');
        const validityEnd = Date.UTC(Number(yearEnd), Number(monthEnd) - 1, Number(dayEnd));
        if (validityEnd < utcNow) {
          return false
        }
      }
      return true;
    });
  });
  giftCertificates = computed(() => {
    const productIds = this.selectionService.center()?.products.map(({ id }) => id);
    return this._activeProducts()
      .filter(p => p.enabled && p.type === ProductType.GiftCertificate && productIds?.includes(p.id));
  })
  managementFee = computed(() => {
    const productIds = this.selectionService.center()?.products.map(({ id }) => id);
    return this._activeProducts()
      .filter(p => p.enabled && p.type === ProductType.ManagementFee && productIds?.includes(p.id))[0] ?? new Product();
  })
  private _productGroups = signal<ProductGroup[]>([]);
  private catalog = computed(() => {
    const productGroups = this._productGroups()
      .filter(p => p.enabled);
    const products = this._activeProducts()
      .filter(p => p.enabled && p.type === ProductType.Standard);
    return [
      ...products.filter(p => !p.group),
      ...productGroups.filter(pg => pg.products.length)];
  })
  ofCurrentCenter = computed(() => {
    const productIds = this.selectionService.center()?.products.map(({ id }) => id);
    return this.catalog().filter(p => productIds?.includes(p.id));
  });
  ofCurrentUniverse = computed(() => {
    const universe = this.selectionService.universe();
    if (universe) {
      return this.ofCurrentCenter()
        .filter(p => p.universes.map(u => u.id).includes(universe.id));
    }
    return this.ofCurrentCenter();
  });
  quickPurchases = computed(() =>
    this.ofCurrentUniverse().filter(p => p.quickPurchase));
  reloadTempo = computed(() => {
    const productIds = this.selectionService.center()?.products
      .filter(({ reloadPass }) => reloadPass)
      .map(({ id }) => id);
    return this.catalog().filter(p => productIds?.includes(p.id));
  });
  reloadVitality = computed(() => {
    const productIds = this.selectionService.center()?.products
      .filter(({ reloadVitality }) => reloadVitality)
      .map(({ id }) => id);
    return this.catalog().filter(p => productIds?.includes(p.id));
  });

  constructor() {
    effect(() => {
      const products = this.quickPurchases();
      const product = this.selectionService.product();
      if (product && !products.find(p => p.id === product.id)) {
        this.selectionService.product.set(null)
      }
    }, { allowSignalWrites: true })
    effect(() => {
      const products = this._activeProducts();
      const productGroups = untracked(() => this._productGroups());
      for (const productGroup of productGroups) {
        productGroup.products = products.filter(p => p.group === productGroup.id);
      }
      this._productGroups.set(productGroups.filter(pg => pg.products.length > 0));
    }, { allowSignalWrites: true })
  }

  /**
   * Load products from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let products: Product[] = [];
    let productGroups: ProductGroup[] = [];
    if (this.transferState.hasKey(this.productsStateKey)) {
      products = this.transferState.get(this.productsStateKey, []).map(p => new Product(p));
    } else {
      const productsQuery = await namedQuery(this.firestore, 'products');
      if (!productsQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(productsQuery.withConverter(Product));
      products = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.productsStateKey, products);
    }
    if (this.transferState.hasKey(this.productGroupsStateKey)) {
      productGroups = this.transferState.get(this.productGroupsStateKey, []).map(p => new ProductGroup(p));
    } else {
      const productGroupsQuery = await namedQuery(this.firestore, 'product-groups');
      if (!productGroupsQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(productGroupsQuery.withConverter(ProductGroup));
      productGroups = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.productGroupsStateKey, productGroups);
    }

    this._productGroups.set(productGroups);
    this._products.set(products);
  }

  get(id: string): Product | ProductGroup {
    return this._productGroups().find(p => p.id === id) ??
      this._products().find(p => p.id === id) ??
      new Product();
  }

  /**
   * Checks if a product is available for the current center.
   * @param id - The ID of the product to check.
   * @returns - True if the product is available for the current center, false otherwise.
   */
  availableForCurrentCenter(id: string): boolean {
    return this._activeProducts().some(p =>
      p.id === id &&
      p.enabled &&
      this.centerService.center()?.products.find(p => p.id === id));
  }

  withErpId(id: number): Product {
    return this._products().find(p => p.erpId === id) ??
      new Product();
  }

  getBySlug(slug: string | null): Product | ProductGroup {
    const product = this._activeProducts().find(p => p.slug === slug && p.enabled);

    if (!product || !this.centerService.center()?.products.find(p => p.id === product.id)) {
      const group = this._productGroups().find(p => p.slug === slug);
      if (group && group.enabled && this.centerService.center()?.products.find(p => p.id === group.id)) {
        return group;
      }
      return new Product();
    }

    if (product.group) {
      const group = this._productGroups().find(p => p.id === product.group);
      if (group && group.enabled && this.centerService.center()?.products.find(p => p.id === group.id)) {
        return group;
      }
    }

    return product;
  }

  translate(centerId: string | undefined, productId: string, key: string): string {
    let tentative = '';
    if (centerId) {
      tentative = this.translateService.instant(`center/${centerId}/product/${productId}/${key}`, {
        noWrap: true,
        orEmpty: true
      });
    }
    return tentative || this.translateService.instant(`product/${productId}/${key}`, { noWrap: true })
  }
}
