import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';

import { emulateClick } from '../core/a11y';
import { HoverGradientDirective } from '../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../shared/directives/progress-click.directive';
import type { Notification, NotificationType } from './notification.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notifications',
  imports: [
    NgxTolgeeModule,
    NgClass,
    HoverGradientDirective,
    ProgressClickDirective
  ],
  templateUrl: './notifications.component.html'
})
/**
 * A container for notifications
 */
export class NotificationsComponent {
  notifications: Notification[] = [];
  readonly emulateClick = emulateClick;
  readonly enterDuration = 300;
  readonly leaveDuration = 200;
  private changeDetectorRef = inject(ChangeDetectorRef);

  open(options: {
         type: NotificationType,
         title: string,
         message: string,
         interpolateParams?: Record<string, string>,
         button?: { action: () => Promise<unknown>, label: string } | null,
         close?: number
       }
  ): void {
    const notification: Notification = { ...options, __animation: 'enter' }
    setTimeout(() => {
      this.notifications.push(notification);
      this.changeDetectorRef.markForCheck();
    }, 0)
    setTimeout(() => {
      notification.__animation = null;
      this.changeDetectorRef.markForCheck();
    }, this.enterDuration);
    if (options.close) {
      setTimeout(() => this.close(notification), options.close)
    }
  }

  close(notification: Notification): void {
    notification.__animation = 'leave';
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
      this.changeDetectorRef.markForCheck();
    }, this.leaveDuration)
  }
}
