import { Profile } from './profile';

export type UserClaim = Profile & {
  admin: boolean,
  roles: Roles[]
};

export enum Roles {
  UserRead = 'user:read',
  UserWrite = 'user:write',
  DataRead = 'data:read',
  DataWrite = 'data:write',
  OtherRead = 'other:read'
}
