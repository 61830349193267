import {DocumentData, QueryDocumentSnapshot, SnapshotOptions, Timestamp} from '@angular/fire/firestore';
import {Cart as _Cart} from '../../../../../core/model/cart/cart.model';

/**
 * Cart Model
 */
export class Cart extends _Cart {
  static toFirestore(cart: Cart): DocumentData {
    return {
      items: cart.items.map(i => ({...i})),
      state: cart.state,
      lastModified: Timestamp.now(),
      promoCodes: cart.promoCodes,
      giftCardCodes: cart.giftCardCodes,
      price: cart.price,
      taxIncluded: cart.taxIncluded,
      tax: cart.tax
    }
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Cart {
    const data = snapshot.data(options);
    return new Cart({
      ...data,
      lastModified: data['lastModified'] ? new Date(data['lastModified'].seconds * 1000) : new Date(),
    });
  }
}
