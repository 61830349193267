/**
 * Common data across all sites
 */
export class MediaDocument {
  media: string;
  alt: string;

  /**
   * Construct mediaDocument from a partial mediaDocument object
   * @param mediaDocument A MediaDocument like object
   */
  constructor(mediaDocument?: Partial<MediaDocument>) {
    this.media = mediaDocument?.media ?? '';
    this.alt = mediaDocument?.alt ?? '';
  }
}

export class CenterImage extends MediaDocument {
  card: boolean;
  header: boolean;

  constructor(centerImage?: Partial<CenterImage>) {
    super(centerImage);
    this.card = centerImage?.card ?? false;
    this.header = centerImage?.header ?? false;

  }
}
