import { DOCUMENT, NgClass, NgComponentOutlet, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Renderer2, Type } from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { DrawerOptions } from './drawer.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-drawer',
  imports: [
    NgClass,
    NgComponentOutlet,
    NgOptimizedImage,
    NgxTolgeeModule
  ],
  templateUrl: './drawer.component.html'
})
/**
 * A drawer that take up the whole screen
 */
export class DrawerComponent {
  duration = 500;
  displayed = false;
  style: 'full' | 'white' | 'transparent' = 'white';
  socials = false;
  title: string = '';
  timeout: ReturnType<typeof setTimeout> | null = null;
  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);
  private changeDetectorRef = inject(ChangeDetectorRef);

  _component: { component: Type<unknown>, inputs: Record<string, unknown> | undefined } | null = null

  get component(): { component: Type<unknown>, inputs: Record<string, unknown> | undefined } | null {
    return this._component;
  }

  set component(value: DrawerOptions | null) {
    if (value) {
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this._component = value
      this.style = value.style ?? 'white';
      this.socials = !!value.socials;
      this.title = value.title ?? '';
      this.displayed = true;
    } else {
      this.displayed = false;
      this.timeout = setTimeout(() => {
        this.renderer.setStyle(this.document.body, 'overflow', 'unset');
        this._component = null;
        this.timeout = null;
      }, this.duration)
    }
    this.changeDetectorRef.markForCheck();
  }
}
