import { MediaDocument } from './media-document.model';

export class Activity {
  id: string;
  name: string;
  icon: string;
  title: string;
  description: string;
  longDescription: string;
  centerVariants: { center: string, longDescription: string, images: MediaDocument[] }[]
  images: MediaDocument[];

  constructor(activity?: Partial<Activity>) {
    this.id = activity?.id ?? '';
    this.name = activity?.name ?? '';
    this.icon = activity?.icon ?? '';
    this.title = activity?.title ?? '';
    this.description = activity?.description ?? '';
    this.longDescription = activity?.longDescription ?? '';
    this.centerVariants = activity?.centerVariants ?? [];
    this.images = activity?.images?.map(i => new MediaDocument(i)) ?? [];
  }
}
