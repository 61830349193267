/**
 * Page Model
 */
export class Page {
    id: string;
    content: string;
    icon: string;
    centerContent: { center: string, content: string }[]

    /**
     * Construct page from a partial page object
     * @param page A Page like object
     */
    constructor(page?: Partial<Page>) {
        this.id = page?.id ?? '';
        this.content = page?.content ?? '';
        this.icon = page?.icon ?? '';
        this.centerContent = page?.centerContent ?? [];
    }
}
