import { inject, Injectable, REQUEST_CONTEXT } from '@angular/core';
import { Firestore, loadBundle } from '@angular/fire/firestore';
import { FirebaseStorage } from '../app.config';

@Injectable({
  providedIn: 'root',
})
/**
 * Center service
 */
export class DataService {
  private readonly dataPath = 'public/data';
  private context = inject<{ version: string }>(REQUEST_CONTEXT, { optional: true });
  private storage = inject(FirebaseStorage);
  private firestore = inject(Firestore);


  /**
   * Get application data a Storage file as an ArrayBuffer
   */
  async loadData(): Promise<void> {
    const version = await this.storage.version(this.dataPath);
    if (this.context?.version !== version) {
      console.debug(`Cache miss`);
      const content = await this.storage.download(this.dataPath)
      await loadBundle(this.firestore, content);
      if (this.context) {
        this.context.version = version;
      }
    }
  }
}
