import { DOCUMENT, isPlatformBrowser, } from '@angular/common';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  effect,
  inject,
  Injectable,
  PLATFORM_ID,
  Type,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, first } from 'rxjs';
import { NavigationService } from '../shared/services/navigation.service';
import { DrawerComponent } from './drawer.component';

export type DrawerOptions = {
  component: Type<unknown>,
  inputs: Record<string, unknown> | undefined,
  style?: 'white' | 'full' | 'transparent',
  title?: string,
  socials?: boolean
}

@Injectable({
  providedIn: 'root',
})
/**
 * Auth service
 */
export class DrawerService {
  private drawer: ComponentRef<DrawerComponent> | null = null;
  private initialNav = true;
  private platformId = inject(PLATFORM_ID);
  private applicationRef = inject(ApplicationRef);
  private document = inject(DOCUMENT);
  private followUp: DrawerOptions | null = null;
  private ignoreNavigationEvent = false;

  /**
   * Create the Drawer and attach it to the app
   */
  constructor() {
    // TODO: Inject Drawer Component server side and, browser side, retrieve it if it exists and inject it
    if (isPlatformBrowser(this.platformId)) {
      const navigationService = inject(NavigationService);
      toObservable(navigationService.navigationEnded)
        .pipe(
          takeUntilDestroyed(),
          debounceTime(1000),
          first()
        )
        .subscribe(() => this.initialNav = false);
      effect(() => {
        navigationService.navigationStarted();
        if (this.initialNav || this.ignoreNavigationEvent) {
          return;
        }
        this.close()
      });
      this.drawer = createComponent(
        DrawerComponent,
        {
          environmentInjector: this.applicationRef.injector,
          hostElement: this.document.getElementById('drawer-host')!
        });
      this.applicationRef.attachView(this.drawer.hostView);
      this.drawer.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Open the drawer
   * @param options -
   * @param followUp -
   */
  open(options: DrawerOptions, followUp?: DrawerOptions): void {
    if (!this.drawer) {
      return;
    }
    this.drawer.instance.component = {
      ...options,
      style: options.style ?? 'white',
      title: options.title ?? '',
    };
    this.followUp = followUp ?? null;
  }

  close(followUp: boolean | null = null): void {
    if (!this.drawer) {
      return;
    }
    if (followUp !== null && this.followUp) {
      if (followUp) {
        this.switch(this.followUp);
      }
      this.followUp = null;
      return;
    }
    this.drawer.instance.component = null;
  }

  switch(options: DrawerOptions): void {
    this.ignoreNavigationEvent = true;
    this.close();
    setTimeout(() => this.open(options), (this.drawer?.instance.duration ?? 0) / 2);
    setTimeout(() => this.ignoreNavigationEvent = false, 1000);
  }
}
