import { computed, inject, Injectable, makeStateKey, signal, TransferState, } from '@angular/core';
import { Firestore, getDocsFromCache, namedQuery, } from '@angular/fire/firestore';
import { TranslateService } from '@tolgee/ngx';
import { Faq } from '../model/faq.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Page Service
 */
export class FaqService {
  faqs = signal<Faq[]>([]);
  faqsGrouped = computed(() => {
    const groups = this.faqs().reduce((groups, faq) => {
      if (!groups[faq.category]) {
        groups[faq.category] = []
      }
      groups[faq.category].push(faq);
      return groups
    }, {} as { [category: string]: Faq[] });
    return Object.entries(groups)
  })
  private readonly faqsStateKey = makeStateKey<Faq[]>('FAQ');
  private transferState = inject(TransferState);
  private translateService = inject(TranslateService);
  private firestore = inject(Firestore);

  /**
   * Load pages from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let faqs: Faq[] = [];
    if (this.transferState.hasKey(this.faqsStateKey)) {
      faqs = this.transferState.get(this.faqsStateKey, []).map(p => new Faq(p));
    } else {
      const faqsQuery = await namedQuery(this.firestore, 'faq');
      if (!faqsQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(faqsQuery.withConverter(Faq));
      faqs = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.faqsStateKey, faqs);
    }

    this.faqs.set(faqs);
  }

  translate(centerId: string | undefined, faqId: string): string {
    let tentative = '';
    if (centerId) {
      tentative = this.translateService.instant(`faq/${faqId}/center/${centerId}/answer`, {
        noWrap: true,
        orEmpty: true
      });
    }
    return tentative || this.translateService.instant(`faq/${faqId}/answer`, { noWrap: true })
  }
}
