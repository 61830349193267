import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injectable,
  input,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { FirebaseFirestore } from '../../app.config';

import { DrawerService } from '../../drawer/drawer.service';
import { InnerHtmlComponent } from '../components/inner-html/inner-html.component';
import { PageNotification } from '../model/page-notification.model';
import { NavigationService } from './navigation.service';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Page Service
 */
export class PageNotificationService {
  notifications = signal<PageNotification[]>([]);
  private readonly pageNotificationsStateKey = makeStateKey<PageNotification[]>('PAGE_NOTIFICATION');
  private transferState = inject(TransferState);
  private firestore = inject(FirebaseFirestore);
  private siteService = inject(SiteService);
  private drawerService = inject(DrawerService);
  private navigationService = inject(NavigationService);

  constructor() {
    effect(() => {
      const navigationEnded = this.navigationService.navigationEnded();
      if (!navigationEnded) {
        return;
      }
      const notifications = this.notifications()
        .filter(notif => {
          if (!notif.target.includes(navigationEnded.url)) {
            return false;
          }
          if (sessionStorage.getItem(`notification-${notif.id}`) !== null) {
            return false
          }
          const now = new Date();
          if (notif.start) {
            const [year, month, day] = notif.start.split('-');
            if (now < new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)))) {
              return false;
            }
          }
          if (notif.end) {
            const [year, month, day] = notif.end.split('-');
            if (now > new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)))) {
              return false;
            }
          }
          if (!notif.center.includes(this.siteService.center()?.id ?? 'landing')) {
            return false;
          }
          return true;
        });
      if (!notifications.length) {
        return;
      }
      notifications.sort((n1, n2) => n1.index - n2.index);
      setTimeout(() => this.drawerService.open({
        component: PageNotificationComponent,
        inputs: {
          notifications
        },
        style: 'full'
      }), 500);
    });
  }


  /**
   * Load pages from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let notifications: PageNotification[] = [];
    if (this.transferState.hasKey(this.pageNotificationsStateKey)) {
      notifications = this.transferState.get(this.pageNotificationsStateKey, []).map(p => new PageNotification(p));
    } else {
      const notifications = await this.firestore.getDocs('notification', PageNotification);
      notifications.sort((a, b) => a.index - b.index)
      this.transferState.set(this.pageNotificationsStateKey, notifications);
    }

    this.notifications.set(notifications);
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-page-notifications',
  imports: [
    NgxTolgeeModule,
    NgOptimizedImage,
    InnerHtmlComponent
  ],
  template: `
    @defer {
      @if (notifications(); as notifications) {
        <div class="w-full h-full grid grid-cols-1"
             [class.md:grid-cols-2]="notifications.length === 2">
          @for (notif of notifications; track notif.id) {
            <div class="basis-1/2 grow px-4 md:px-20 py-16 md:py-8"
                 [style.background-color]="notif.color">
              <h2 class="font-bold my-8 text-4xl leading-tight text-white text-center">
                {{ 'notification/' + notif.id + '/title' | translate }}
              </h2>

              <div class="overflow-hidden relative m-8 md:max-w-[50%] mx-auto rounded-lg aspect-[11/6]">
                @if (notif.image; as image) {
                  <img fill=""
                       class="object-cover"
                       [alt]="image.alt"
                       [ngSrc]="image.media"
                       priority>
                } @else {
                  <img fill=""
                       class="object-cover"
                       [alt]="'notification/' + notif.id + '/title' | translate"
                       ngSrc="assets/images/placeholder.webp"
                       priority>
                }
              </div>

              <app-inner-html [html]="'notification/' + notif.id + '/content' | translate"
                              classes="text-lg leading-tight text-white md:max-w-[50%] mx-auto"></app-inner-html>
            </div>
          }
        </div>
      }
    }
  `
})
/**
 * A container for notifications
 */
export class PageNotificationComponent {
  notifications = input.required<PageNotification[]>();

  constructor() {
    effect(() => {
      for (const notif of this.notifications()) {
        sessionStorage.setItem(`notification-${notif.id}`, '');
      }
    });
  }
}
