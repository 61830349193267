import {
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import {
  Firestore,
  getDocsFromCache,
  namedQuery,
} from '@angular/fire/firestore';
import { Cse } from '../model/global/cse.model';
import { Global } from '../model/global/global.model';
import { Landing } from '../model/global/landing.model';
import { Press } from '../model/global/press.model';
import { Settings } from '../model/global/settings.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Category Service
 */
export class GlobalService {
  private readonly globalsStateKey = makeStateKey<Global>('GLOBALS');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  landing = signal<Landing>(new Landing());
  cse = signal<Cse>(new Cse());
  press = signal<Press>(new Press());
  settings = signal<Settings>(new Settings());

  /**
   * Load universes from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let global = new Global();
    if (this.transferState.hasKey(this.globalsStateKey)) {
      global = this.transferState.get(this.globalsStateKey, new Global());

    } else {
      const globalsQuery = await namedQuery(this.firestore, 'global');
      if (!globalsQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(globalsQuery);
      snapshot.forEach(docSnap => {
        switch (docSnap.id) {
        case 'landing':
          global.landing = Landing.fromFirestore(docSnap);
          break;
        case 'cse':
          global.cse = Cse.fromFirestore(docSnap);
          break;
        case 'press':
          global.press = Press.fromFirestore(docSnap);
          break;
        case 'settings':
          global.settings = Settings.fromFirestore(docSnap);
          break;
        }
      })
      this.transferState.set(this.globalsStateKey, global);
    }

    this.landing.set(global.landing);
    this.cse.set(global.cse);
    this.press.set(global.press);
    this.settings.set(global.settings);
  }
}
