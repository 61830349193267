import { inject } from '@angular/core';
import {
  applyActionCode as _applyActionCode,
  Auth,
  checkActionCode as _checkActionCode,
  confirmPasswordReset as _confirmPasswordReset,
  EmailAuthProvider,
  FacebookAuthProvider,
  getRedirectResult as _getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential as _linkWithCredential,
  OAuthProvider,
  onIdTokenChanged,
  signInAnonymously as _signInAnonymously,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as _signOut,
  verifyPasswordResetCode as _verifyPasswordResetCode,
} from '@angular/fire/auth';
import type { FirebaseError } from '@firebase/util';
import { NotificationService } from '../../../notification/notification.service';
import {
  FirebaseAuthInterface,
  FirebaseUser
} from './auth.interface';

export class FirebaseAuthWeb implements FirebaseAuthInterface {
  auth = inject(Auth);
  private notification = inject(NotificationService);

  async applyActionCode(oobCode: string): Promise<void> {
    await _applyActionCode(this.auth, oobCode);
  }

  async checkActionCode(oobCode: string): Promise<string | null> {
    const { data: { email } } = await _checkActionCode(this.auth, oobCode);
    return email ?? null;
  }

  async confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    await _confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  async getIdToken(forceRefresh: boolean = false): Promise<string | null> {
    return await this.auth.currentUser?.getIdToken(forceRefresh) ?? null;
  }

  async linkWithEmailAndPassword(email: string, password: string): Promise<void> {
    await _linkWithCredential(this.auth.currentUser!, EmailAuthProvider.credential(email, password))
  }

  async signOut(): Promise<void> {
    await _signOut(this.auth);
  }

  onIdTokenChanged(fn: (user: FirebaseUser) => void): void {
    onIdTokenChanged(this.auth, fn)
  }

  async signInAnonymously(): Promise<void> {
    await _signInAnonymously(this.auth);
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<void> {
    await _signInWithEmailAndPassword(this.auth, email, password);
  }

  async signInWithGoogle(): Promise<void> {
    await signInWithRedirect(this.auth, new GoogleAuthProvider());
  }

  async signInWithFacebook(): Promise<void> {
    await signInWithRedirect(this.auth, new FacebookAuthProvider());
  }

  async signInWithApple(): Promise<void> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    await signInWithRedirect(this.auth, provider);
  }

  verifyPasswordResetCode(oobCode: string): Promise<string> {
    return _verifyPasswordResetCode(this.auth, oobCode);
  }

  async getRedirectResult(): Promise<void> {
    try {
      await _getRedirectResult(this.auth);
    } catch (err) {
      const error = err as FirebaseError;
      if (error.code !== 'auth/account-exists-with-different-credential') {
        this.notification.open({ type: 'error', title: 'error', message: error.message });
        return;
      }
      if (error.customData) {
        const { providerId, verifiedProvider } = error.customData['_tokenResponse'] as {
          providerId: string,
          verifiedProvider: string[]
        };
        this.notification.open({
          type: 'message',
          title: 'accountExistsWithDifferentCredential',
          message: 'accountExistsWithDifferentCredentialNative',
          interpolateParams: {
            providers: verifiedProvider.join(', '), providerId
          }
        });
        return;
      }
    }
  }
}
