/**
 * Common data across all sites
 */
export class Cse {
  title: string;
  subTitle: string;
  sections: {
    title: string;
    description: string;
    image: string;
  }[];

  /**
   * Construct cse from a partial cse object
   * @param cse A Cse like object
   */
  constructor(cse?: Partial<Cse>) {
    this.title = cse?.title ?? '';
    this.subTitle = cse?.subTitle ?? '';
    this.sections = cse?.sections?.map(s => ({
      title: s.title ?? '',
      description: s.description ?? '',
      image: s.image ?? ''
    })) ?? [];
  }
}
