export const blobToBase64 = (blob: Blob): Promise<string | null> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function () {
      let base64String;
      if (reader.result instanceof ArrayBuffer) {
        base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
      } else {
        base64String = reader.result;
      }
      return resolve(base64String);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
      return resolve(null);
    };
  });
};
