import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Event as _Event} from '../../../../../core/model/event.model'

/**
 * Event Model
 */
export class Event extends _Event {

  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Event {
    return new Event({
      ...snapshot.data(options),
      id: snapshot.id,
      description: '',
      name: '',
      pageContent: '',
      title: ''
    });
  }
}
