import {Activity} from './activity.model';
import {MediaDocument} from './media-document.model';

/**
 * Center Model
 */
export class Universe {
    id: string;
    index: number;
    name: string;
    color: string;
    title: string;
    description: string;
    icon: string;
    images: MediaDocument[];
    activities: Activity[];

    /**
     * Construct universe from a partial center universe
     * @param universe A Universe like object
     */
    constructor(universe?: Partial<Universe>) {
        this.id = universe?.id ?? '';
        this.index = universe?.index ?? 0;
        this.name = universe?.name ?? '';
        this.color = universe?.color ?? '';
        this.title = universe?.title ?? '';
        this.description = universe?.description ?? '';
        this.icon = universe?.icon ?? 'bain.svg';
        this.images = universe?.images?.map(i => new MediaDocument(i)) ?? [];
        this.activities = universe?.activities?.map(activity => new Activity(activity)) ?? [];
    }
}
