import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from '@angular/fire/firestore';
import { Cse as _Cse } from '../../../../../../core/model/global/cse.model'

export class Cse extends _Cse {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Cse {
    return new Cse({
      ...snapshot.data(options)
    });
  }
}
