import { inject, Injectable, makeStateKey, REQUEST, signal, TransferState, } from '@angular/core';
import { Firestore, getDocsFromCache, namedQuery, } from '@angular/fire/firestore';
import { Capacitor } from '@capacitor/core';
import { FIREBASE_ADMIN, FirebaseFunctions } from '../../app.config';
import { Center } from '../model/center.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Center service
 */
export class CenterService {
  centers = signal<Center[]>([]);
  center = signal<Center | null>(null);
  private admin = inject(FIREBASE_ADMIN, { optional: true });
  private req = inject(REQUEST, { optional: true });
  private readonly centersStateKey = makeStateKey<Center[]>('CENTERS');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  private functions = inject(FirebaseFunctions)

  /**
   * Load centers from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let centers: Center[] = [];
    if (this.transferState.hasKey(this.centersStateKey)) {
      centers = this.transferState.get(this.centersStateKey, []).map(p => new Center(p));
    } else {
      const centersQuery = await namedQuery(this.firestore, 'centers');
      if (!centersQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(centersQuery.withConverter(Center));
      centers = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.centersStateKey, centers);
    }
    this.centers.set(centers);
    this.initSiteCenter();
  }

  get(id: string): Center {
    return this.centers().find(p => p.id === id) ??
      new Center();
  }

  withErpId(id: number): Center {
    return this.centers().find(p => p.erpId === id) ??
      new Center();
  }

  phoneNumber(erpId: number): string {
    switch (erpId) {
    case 1:
      return '01 46 20 07 01';
    case 2:
      return '01 55 28 95 75';
    case 3:
      return '03 20 99 99 59';
    case 4:
      return '03 90 22 78 78';
    case 5:
      return '04 37 23 34 60';
    case 6:
      return '05 56 11 56 46';
    default:
      return '';
    }
  }

  formattedPhoneNumber(phone: string): string {
    return phone.replace(/ /g, '').replace('0', '+33')
  }

  async subscribeToNewsletter(email: string, newsletterListId: string): Promise<void> {
    return this.functions.httpsCallable<{
      email: string,
      newsletterListId: string
    }, void>('emailing-subscribeNewsletter', { email, newsletterListId });
  }

  private initSiteCenter(): void {
    let host: string;
    if (this.admin) {
      host = this.req?.headers.get('x-forwarded-host') ?? this.req?.headers.get('host') ?? 'localhost'
    } else {
      host = location.hostname;
    }
    if (!Capacitor.isNativePlatform() && host.startsWith('localhost')) {
      host = 'les100ciels-paris.agileinteractive.fr'
    }
    const center = this.centers()
      .filter(c => !!c.erpId)
      .find(c => c.url.startsWith(`https://${host}`)) ?? null;
    if (center) {
      this.center.set(new Center(center));
    }
  }
}
