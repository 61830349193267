import { IMAGE_LOADER, ImageLoaderConfig, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  inject,
  InjectionToken,
  Injector,
  LOCALE_ID,
  PLATFORM_ID,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  browserPopupRedirectResolver,
  connectAuthEmulator,
  debugErrorMap,
  indexedDBLocalPersistence,
  initializeAuth,
  prodErrorMap,
  provideAuth,
} from '@angular/fire/auth';
import { connectFirestoreEmulator, Firestore, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { Meta, provideClientHydration, Title, withEventReplay } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  ActivatedRouteSnapshot,
  provideRouter,
  RouterStateSnapshot,
  TitleStrategy,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  BackendFetch,
  DevTools,
  FormatSimple,
  LanguageStorage,
  NgxTolgeeModule,
  Tolgee,
  TOLGEE_INSTANCE,
  TolgeeInstance,
  TranslateService
} from '@tolgee/ngx';

import { app } from 'firebase-admin';
import 'moment/locale/fr'
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { MediaDocument } from '../../../core/model/media-document.model';
import { environment } from '../environments/environment';

import { routes } from './app.routes';
import { FirebaseAnalyticsInterface } from './core/firebase/analytics/analytics.interface';
import { FirebaseAnalyticsNative } from './core/firebase/analytics/analytics.native';
import { FirebaseAnalyticsWeb } from './core/firebase/analytics/analytics.web';
import { FirebaseAuthInterface } from './core/firebase/auth/auth.interface';
import { FirebaseAuthNative } from './core/firebase/auth/auth.native';
import { FirebaseAuthWeb } from './core/firebase/auth/auth.web';
import { FirebaseFirestoreInterface } from './core/firebase/firestore/firestore.interface';
import { FirebaseFirestoreNative } from './core/firebase/firestore/firestore.native';
import { FirebaseFirestoreWeb } from './core/firebase/firestore/firestore.web';
import { FirebaseFunctionsInterface } from './core/firebase/functions/functions.interface';
import { FirebaseFunctionsNative } from './core/firebase/functions/functions.native';
import { FirebaseFunctionsWeb } from './core/firebase/functions/functions.web';
import { FirebaseStorageInterface } from './core/firebase/storage/storage.interface';
import { FirebaseStorageNative } from './core/firebase/storage/storage.native';
import { FirebaseStorageServer } from './core/firebase/storage/storage.server';
import { FirebaseStorageWeb } from './core/firebase/storage/storage.web';
import { NotificationService } from './notification/notification.service';
import { Page } from './shared/model/page.model';
import { ProductGroup } from './shared/model/product-group.model';
import { Product } from './shared/model/product.model';
import { SiteService } from './shared/services/site.service';

export const FIREBASE_ADMIN = new InjectionToken<app.App>('firebase-admin');
export const FirebaseAuth = new InjectionToken<FirebaseAuthInterface>('FirebaseAuth');
export const FirebaseAnalytics = new InjectionToken<FirebaseAnalyticsInterface>('FirebaseAnalytics');
export const FirebaseFunctions = new InjectionToken<FirebaseFunctionsInterface>('FirebaseFunctions');
export const FirebaseFirestore = new InjectionToken<FirebaseFirestoreInterface>('FirebaseFirestore');
export const FirebaseStorage = new InjectionToken<FirebaseStorageInterface>('FirebaseStorage');

registerLocaleData(localeFr);

export const appConfig = (options: FirebaseOptions): ApplicationConfig => {
  const providers = [
    provideExperimentalZonelessChangeDetection(),
    //provideExperimentalCheckNoChangesForDebug(),
    provideShareButtonsOptions(shareIcons()),
    importProvidersFrom(NgxTolgeeModule),
    {
      provide: TOLGEE_INSTANCE,
      deps: [Injector],
      useFactory: (injector: Injector): TolgeeInstance => {
        const cache = new Set(['Missing translation key']);
        const notification = injector.get(NotificationService)
        const tolgee = Tolgee()
          .use(FormatSimple())
          .use(BackendFetch({ prefix: 'https://cdn.tolg.ee/691e1a901064b2ce060a2226d2af70bd' }));
        if (!environment.production) {
          tolgee.use(DevTools())
        }
        if (isPlatformBrowser(injector.get(PLATFORM_ID))) {
          tolgee
            .use(LanguageStorage())
        }
        return tolgee
          .init({
            availableLanguages: ['fr', 'en'],
            defaultLanguage: 'fr',
            fallbackLanguage: 'fr',
            onTranslationMissing: info => {
              if (environment.production || info.orEmpty) {
                return '';
              }
              if (!cache.has(info.key)) {
                cache.add(info.key);
                notification.open({
                  type: 'warning',
                  title: 'Missing translation key',
                  message: info.key
                });
              }
              return info.key
            }
          });
      }
    },
    {
      provide: LOCALE_ID, useValue: 'fr-FR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: TitleStrategy,
      useFactory: titleStrategyFactory
    },
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    provideHttpClient(),
    provideAnimations(),
    {
      provide: FirebaseAuth,
      useFactory: firebaseAuthFactory
    },
    {
      provide: FirebaseAnalytics,
      useFactory: firebaseAnalyticsFactory
    },
    {
      provide: FirebaseFirestore,
      useFactory: firebaseFirestoreFactory
    },
    {
      provide: FirebaseFunctions,
      useFactory: firebaseFunctionsFactory
    },
    {
      provide: FirebaseStorage,
      useFactory: firebaseStorageFactory,
      deps: [Injector]
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig): string => {
        if (!config) {
          return 'assets/images/placeholder.webp'
        }
        if (config.src.startsWith('assets') || config.src.startsWith('http')) {
          return config.src
        }
        const w = config.width ? `&w=${config.width}` : '';
        return `https://${environment.cdn}/cdn/image?id=${config.src}${w}`;
      }
    },
    provideAppInitializer(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformBrowser(platformId)) {
        return;
      }
      const tolgee = inject(TranslateService).tolgee;
      //const lang = injector.get(REQUEST).headers['accept-language']?.substring(0, 2) ?? 'fr';
      const lang = 'fr';
      return new Promise(resolve => {
        tolgee.changeLanguage(lang)
          .then(() => {
            tolgee.on('initialLoad', () => resolve(null));
            tolgee.run();
          });
      });
    }),
    provideFirebaseApp(() => {
      if (isPlatformBrowser(inject(PLATFORM_ID)) && !Capacitor.isNativePlatform()) {
        if (window.location.hostname !== 'localhost') {
          options.authDomain = window.location.hostname;
        }
      }
      return initializeApp(options);
    }),
    provideFirestore(((): (injector: Injector) => Firestore => {
      let firestore: Firestore;
      return injector => {
        if (!firestore) {
          firestore = getFirestore(injector.get(FirebaseApp));
          if (environment.useEmulator) {
            connectFirestoreEmulator(firestore, 'localhost', 8080);
          }
        }
        return firestore;
      };
    })()),
    provideStorage(injector => {
      const storage = getStorage(injector.get(FirebaseApp));
      if (environment.useEmulator) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
  ]
  if (!Capacitor.isNativePlatform()) {
    providers.push(importProvidersFrom(UserTrackingService));
    providers.push(importProvidersFrom(ScreenTrackingService));
    providers.push(providePerformance(injector => getPerformance(injector.get(FirebaseApp))));
    providers.push(provideFunctions(injector => {
      const functions = getFunctions(injector.get(FirebaseApp), 'europe-west1');
      if (environment.useEmulator) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }));
    providers.push(provideAuth(injector => {
      const app = injector.get(FirebaseApp);
      const admin = injector.get(FIREBASE_ADMIN, null);
      const auth = initializeAuth(app, {
        errorMap: environment.production ? prodErrorMap : debugErrorMap,
        persistence: Capacitor.isNativePlatform() ? indexedDBLocalPersistence : indexedDBLocalPersistence,
        popupRedirectResolver: admin ? undefined : browserPopupRedirectResolver
      });
      if (environment.useEmulator) {
        connectAuthEmulator(auth, 'http://localhost:9099');
      }
      return auth;
    }));
    providers.push(provideClientHydration(withEventReplay()));
    providers.push(provideAnalytics(injector => getAnalytics(injector.get(FirebaseApp))));
  }
  return { providers };
};

/**
 * FirebaseAuth factory
 * @returns FirebaseAuthInterface instance
 */
export function firebaseFunctionsFactory(): FirebaseFunctionsInterface {
  return Capacitor.isNativePlatform() ?
    new FirebaseFunctionsNative() :
    new FirebaseFunctionsWeb();
}

/**
 * FirebaseStorage factory
 * @param injector The injector
 * @returns FirebaseAuthInterface instance
 */
export function firebaseStorageFactory(injector: Injector): FirebaseStorageInterface {
  if (Capacitor.isNativePlatform()) {
    return new FirebaseStorageNative();
  } else {
    const admin = injector.get(FIREBASE_ADMIN, null);
    return admin ? new FirebaseStorageServer(admin) : new FirebaseStorageWeb();
  }
}

/**
 * FirebaseAuth factory
 * @returns FirebaseAuthInterface instance
 */
export function firebaseAuthFactory(): FirebaseAuthInterface {
  return Capacitor.isNativePlatform() ?
    new FirebaseAuthNative() :
    new FirebaseAuthWeb();
}

/**
 * FirebaseAnalytics factory
 * @returns FirebaseAnalyticsInterface instance
 */
export function firebaseAnalyticsFactory(): FirebaseAnalyticsInterface {
  return Capacitor.isNativePlatform() ?
    new FirebaseAnalyticsNative() :
    new FirebaseAnalyticsWeb();
}

/**
 * FirebaseFirestore factory
 * @returns FirebaseFirestoreInterface instance
 */
export function firebaseFirestoreFactory(): FirebaseFirestoreInterface {
  return Capacitor.isNativePlatform() ?
    new FirebaseFirestoreNative() :
    new FirebaseFirestoreWeb();
}

/**
 * Returns the title strategy
 * @returns The TitleStrategy
 */
export function titleStrategyFactory(): TitleStrategy {
  return new PageTitleStrategy();
}

export class PageTitleStrategy extends TitleStrategy {
  title = inject(Title);
  meta = inject(Meta);
  translateService = inject(TranslateService);
  siteService = inject(SiteService);

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const titleFragment = [];
    const title = this.buildTitle(snapshot);

    let pageData: Page | null = null;
    let productData: Product | ProductGroup | null = null;
    let route: ActivatedRouteSnapshot | null = snapshot.root;
    let image: MediaDocument | null = null;
    do {
      if (!pageData && route.data['page']) {
        pageData = route.data['page']
      }
      if (!productData && route.data['product']) {
        productData = route.data['product']
      }
      route = route.firstChild;
    } while (route);
    if (title) {
      titleFragment.push(this.translateService.instant(title));
    } else if (productData) {
      titleFragment.push(this.translateService.instant('product/' + productData.id + '/name'))
      image = productData.images[0]
    } else if (pageData) {
      titleFragment.push(this.translateService.instant(pageData.id))
    }
    const center = this.siteService.center();
    if (center) {
      const centerName = center ? `Les Cents Ciels ${center.name} ${center.city}` : '';
      titleFragment.push(centerName);
      image = image ?? center.images[0];
    }
    const newTitle = titleFragment.join(' | ');
    this.title.setTitle(newTitle);
    this.meta.updateTag({ name: 'twitter:title', content: newTitle });
    this.meta.updateTag({ name: 'og:title', content: newTitle });
    if (image) {
      const imageUrl = `https://${environment.cdn}/cdn/image?id=${image.media}`;
      this.meta.updateTag({ name: 'og:image', content: imageUrl });
      this.meta.updateTag({ name: 'og:image:alt', content: image.alt });
      this.meta.updateTag({ name: 'twitter:image:src', content: imageUrl });
      this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    }
  }
}
