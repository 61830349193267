import {
  DocumentData,
  FirestoreDataConverter,
  WhereFilterOp
} from '@angular/fire/firestore';
import {
  FirebaseFirestore,
  GetCollectionOptions,
} from '@capacitor-firebase/firestore';
import { FirebaseFirestoreInterface } from './firestore.interface';

export class FirebaseFirestoreNative implements FirebaseFirestoreInterface {

  async getDoc<T, U extends DocumentData>(
    path: string,
    _class: { new(t?: Partial<T>): T } & FirestoreDataConverter<T, U>
  )
    : Promise<T | undefined> {
    const { snapshot } = await FirebaseFirestore.getDocument({
      reference: path,
    })
    const data = { ...snapshot.data, id: snapshot.id };
    return new _class(data as unknown as Partial<T> ?? undefined);
  }

  async setDoc<T, U extends DocumentData>(
    path: string,
    data: T,
    _class: FirestoreDataConverter<T, U>
  ): Promise<void> {
    await FirebaseFirestore.setDocument({
      reference: path,
      data: _class.toFirestore(data)
    })
  }

  async deleteDoc(path: string): Promise<void> {
    await FirebaseFirestore.deleteDocument({
      reference: path
    })
  }

  async getDocs<T, U extends DocumentData>(
    path: string,
    _class: { new(t?: Partial<T>): T } & FirestoreDataConverter<T, U>,
    queryConstraints?: { fieldPath: string, opStr: WhereFilterOp, value: unknown }[],
  ): Promise<T[]> {
    const options: GetCollectionOptions = { reference: path };
    if (queryConstraints) {
      options.compositeFilter = {
        type: 'and',
        queryConstraints: queryConstraints.map(({ fieldPath, opStr, value }) =>
          ({ type: 'where', fieldPath, opStr, value }))
      }
    }
    const snapshot = await FirebaseFirestore.getCollection(options)
    return snapshot.snapshots.map(snapshot => {
      const data = { ...snapshot.data, id: snapshot.id };
      return new _class(data as unknown as Partial<T> ?? undefined);
    })
  }

  async onSnapshotDocument(path: string, callback: (snapshot: any) => void): Promise<() => void> {
    const callbackId = await FirebaseFirestore.addDocumentSnapshotListener({
      reference: path
    }, result => {
      callback(result?.snapshot.data)
    });
    return () => FirebaseFirestore.removeSnapshotListener({ callbackId })
  }

  async onSnapshotCollection(path: string, callback: (snapshot: any) => void): Promise<() => void> {
    const callbackId = await FirebaseFirestore.addCollectionSnapshotListener({
      reference: path
    }, result => {
      callback(result?.snapshots.map(doc => doc.data))
    });
    return () => FirebaseFirestore.removeSnapshotListener({ callbackId })
  }
}
