import { isPlatformBrowser } from '@angular/common';
import {
  inject,
  Injectable,
  PLATFORM_ID
} from '@angular/core';
import { CartItem } from '../../../../../core/model/cart/cart-item.model';
import { FirebaseAnalytics } from '../../app.config';
import { Product } from '../model/product.model';
import { CategoryService } from './category.service';
import { ProductService } from './product.service';
import { SiteService } from './site.service';


declare global {
  interface Window {
    fbq(t: 'track' | 'trackCustom', eventName: string, data: Record<string, unknown>): void
  }
}

@Injectable({
  providedIn: 'root',
})
/**
 * Contact service
 */
export class AnalyticsService {
  private analytics = inject(FirebaseAnalytics);
  private siteService = inject(SiteService)
  private productService = inject(ProductService)
  private categoryService = inject(CategoryService)
  private fb =
    ((): (t: 'track' | 'trackCustom', eventName: string, data: Record<string, unknown>) => void => {
      if (isPlatformBrowser(inject(PLATFORM_ID))) {
        if (window.fbq) {
          return window.fbq;
        }
      }
      return function (): void {
      };
    })()

  login(method: string): void {
    try {
      this.fb('trackCustom', 'Login', { method });
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent('login', { method });
    } catch (e) {
      // IGNORE
    }
  }

  signup(method: string): void {
    try {
      this.fb('trackCustom', 'SignUp', { method })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent('sign_up', { method });
    } catch (e) {
      // IGNORE
    }
  }

  search(search_term: string): void {
    try {
      this.fb('track', 'Search', { search_string: search_term })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent('search', { search_term });
    } catch (e) {
      // IGNORE
    }
  }

  share(method: 'twitter' | 'facebook', erpCode: string): void {
    try {
      this.fb('trackCustom', 'Share', { method })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent('share', { method, content_type: 'product', item_id: erpCode });
    } catch (e) {
      // IGNORE
    }
  }

  viewItem(product: Product): void {
    const prices = this.siteService.price(product.id);
    const price = prices.price * 10 / 12;
    const categories = product.categories.map(c => this.categoryService.get(c)).map(c => c.name);
    try {
      this.fb('track', 'ViewContent', {
        content_ids: [ product.erpCode ],
        content_name: product.name,
        content_category: 'product',
        content_type: 'product',
        currency: 'EUR',
        contents: [ {
          id: product.erpCode,
          quantity: 1,
          categories,
          value: price,
        } ],
        value: price,
      })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent(
        'view_item',
        {
          currency: 'EUR',
          value: prices.price * 10 / 12,
          items: [ {
            item_id: product.erpCode,
            item_name: product.name,
            item_category: categories[0],
            item_category2: categories[1],
            item_category3: categories[2],
            item_category4: categories[3],
            item_category5: categories[4],
            price: prices.price * 10 / 12,
            quantity: 1
          } ]
        });
    } catch (e) {
      // IGNORE
    }
  }

  viewCart(cartItems: { cartItem: CartItem, quantity?: number }[]): void {
    const items = this.cartItems(cartItems);
    const total = items.reduce((sum, item) =>
      sum + item.price * item.quantity, 0);
    try {
      this.fb('trackCustom', 'ViewCart', {
        content_ids: items.map(i => i.item_id),
        content_name: 'checkout',
        currency: 'EUR',
        contents: items.map(i => ({
          id: i.item_id,
          quantity: i.quantity,
          categories: [ i.item_category, i.item_category2, i.item_category3, i.item_category4, i.item_category5 ],
          value: i.price,
        })),
        value: total
      })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent('view_cart', {
        currency: 'EUR',
        value: total,
        items
      });
    } catch (e) {
      // IGNORE
    }
  }

  addToCart(cartItems: { cartItem: CartItem, quantity?: number }[]): void {
    const items = this.cartItems(cartItems);
    try {
      this.fb('track', 'AddToCart', {
        content_ids: items.map(i => i.item_id),
        content_name: 'product',
        content_type: 'product',
        currency: 'EUR',
        contents: items.map(i => ({
          id: i.item_id,
          quantity: i.quantity,
          categories: [ i.item_category, i.item_category2, i.item_category3, i.item_category4, i.item_category5 ],
          value: i.price,
        })),
        value: items.reduce((sum, item) => sum + item.price * item.quantity, 0)
      })
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent(
        'add_to_cart',
        {
          currency: 'EUR',
          value: items.reduce((sum, item) => sum + item.price * item.quantity, 0),
          items
        });
    } catch (e) {
      // IGNORE
    }
  }

  removeFromCart(cartItems: { cartItem: CartItem, quantity?: number }[]): void {
    const items = this.cartItems(cartItems);
    const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    try {
      this.fb('trackCustom', 'RemoveFromCart', {
        content_ids: items.map(i => i.item_id),
        content_name: 'product',
        content_type: 'product',
        currency: 'EUR',
        contents: items.map(i => ({
          id: i.item_id,
          quantity: i.quantity,
          categories: [ i.item_category, i.item_category2, i.item_category3, i.item_category4, i.item_category5 ],
          value: i.price,
        })),
        value: total
      });
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent(
        'remove_from_cart',
        {
          currency: 'EUR',
          value: total,
          items
        });
    } catch (e) {
      // IGNORE
    }
  }

  beginCheckout(cartItems: { cartItem: CartItem, quantity?: number }[]): void {
    const items = this.cartItems(cartItems);
    const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    try {
      this.fb('track', 'InitiateCheckout', {
        content_ids: items.map(i => i.item_id),
        content_name: 'checkout',
        currency: 'EUR',
        contents: items.map(i => ({
          id: i.item_id,
          quantity: i.quantity,
          categories: [ i.item_category, i.item_category2, i.item_category3, i.item_category4, i.item_category5 ],
          value: i.price,
        })),
        value: total
      });
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent(
        'begin_checkout',
        {
          currency: 'EUR',
          value: items.reduce((sum, item) => sum + item.price * item.quantity, 0),
          items
        });
    } catch (e) {
      // IGNORE
    }
  }

  purchase(transactionId: string, coupon: string | undefined, cartItems: { cartItem: CartItem, quantity?: number }[])
    : void {
    const items = this.cartItems(cartItems);
    const total = items.reduce((sum, item) =>
      sum + item.price * item.quantity, 0);
    try {
      this.fb('track', 'Purchase', {
        content_ids: items.map(i => i.item_id),
        content_name: 'checkout',
        currency: 'EUR',
        contents: items.map(i => ({
          id: i.item_id,
          quantity: i.quantity,
          categories: [ i.item_category, i.item_category2, i.item_category3, i.item_category4, i.item_category5 ],
          value: i.price,
        })),
        value: total
      });
    } catch (e) {
      // IGNORE
    }
    try {
      this.analytics.logEvent(
        'purchase',
        {
          currency: 'EUR',
          value: total,
          transaction_id: transactionId,
          coupon,
          tax: total * .2,
          items
        });
    } catch (e) {
      // IGNORE
    }
  }

  private cartItems =
    (cartItems: { cartItem: CartItem, quantity?: number }[]) => cartItems.map(({
      cartItem,
      quantity
    }) => {
      const product = this.productService.get(cartItem.product);
      const prices = this.siteService.price(cartItem.product);
      const categories = product.categories.map(c => this.categoryService.get(c)).map(c => c.name);
      return {
        item_id: product.erpCode,
        item_name: product.name,
        item_category: categories[0],
        item_category2: categories[1],
        item_category3: categories[2],
        item_category4: categories[3],
        item_category5: categories[4],
        price: prices.price * 10 / 12,
        quantity: quantity || 1
      }
    });
}
