import { Cse } from './cse.model';
import { Landing } from './landing.model';
import { Press } from './press.model';
import { Settings } from './settings.model';

export class Global {
  landing: Landing = new Landing();
  cse: Cse = new Cse();
  press: Press = new Press();
  settings: Settings = new Settings();
}
