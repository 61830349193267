export enum Template {
  ResetPassword = 'ResetPassword',
  ConfirmEmail = 'ConfirmEmail',
  UpdateEmail = 'UpdateEmail',
  ContactEmail = 'ContactEmail',
  CseEmail = 'CseEmail',
  PressEmail = 'PressEmail',
  ContactReceived = 'ContactReceived',
  NewsletterSubscribed = 'NewsletterSubscribed',
  YourPass = 'YourPass',
}
