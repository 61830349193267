import {CenterImage, MediaDocument} from './media-document.model';

/**
 * Center Model
 */
export class Center {
    id: string;
    enabled: boolean;
    erpId: number;
    index: number;
    name: string;
    city: string;
    url: string;
    description: string;
    openingHours: string;
    address: string;
    booklet: MediaDocument | null;
    planning: MediaDocument | null;
    trustIndexReview: string;
    trustIndexNote: string;
    facebook: string;
    products: {
        id: string,
        price: number,
        fullPrice: number,
        promo: boolean,
        reloadPass: boolean,
        reloadVitality: boolean,
        longDescription: string,
        longDescription2: string
        goodToKnow: string
        access: string
        bePrepared: string
        images: MediaDocument[]
    }[];
    images: CenterImage[];
    lat: number;
    lng: number;
    capacity: number;
    waterTemp: number;
    geoHash: string;
    infoBaths: string;
    infoSpa: string;
    infoPlanning: string;
    infoMustTake: string;
    infoWhoAllowed: string;
    newsletterListId: string;
    contacts: string[];
    cseContacts: string[];
    paypalClientId: string;


    /**
     * Construct center from a partial center object
     * @param center A Center like object
     */
    constructor(center?: Partial<Center>) {
        this.id = center?.id ?? '';
        this.enabled = center?.enabled ?? false;
        this.erpId = center?.erpId ?? 0;
        this.index = center?.index ?? 0;
        this.name = center?.name ?? '';
        this.city = center?.city ?? '';
        this.url = center?.url ?? '';
        this.description = center?.description ?? '';
        this.openingHours = center?.openingHours ?? '';
        this.capacity = center?.capacity ?? 1000;
        this.waterTemp = center?.waterTemp ?? 34;
        this.address = center?.address ?? '';
        this.booklet = center?.booklet ? new MediaDocument(center.booklet) : null;
        this.planning = center?.planning ? new MediaDocument(center.planning) : null;
        this.trustIndexReview = center?.trustIndexReview ?? '';
        this.trustIndexNote = center?.trustIndexNote ?? '';
        this.facebook = center?.facebook ?? '';
        this.products = center?.products?.map(p => ({
            id: p.id ?? '',
            price: p.price ?? 0,
            fullPrice: p.fullPrice ?? 0,
            promo: p.promo ?? false,
            reloadPass: p.reloadPass ?? false,
            reloadVitality: p.reloadVitality ?? false,
            longDescription: p.longDescription ?? '',
            longDescription2: p.longDescription2 ?? '',
            goodToKnow: p.goodToKnow ?? '',
            access: p.access ?? '',
            bePrepared: p.bePrepared ?? '',
            images: p.images?.map(i => new MediaDocument(i)) ?? []
        })) ?? [];
        this.images = center?.images?.map(i => new CenterImage(i)) ?? [];
        this.lat = center?.lat ?? 0;
        this.lng = center?.lng ?? 0;
        this.geoHash = center?.geoHash ?? '';
        this.infoBaths = center?.infoBaths ?? '';
        this.infoSpa = center?.infoSpa ?? '';
        this.infoPlanning = center?.infoPlanning ?? '';
        this.infoMustTake = center?.infoMustTake ?? '';
        this.infoWhoAllowed = center?.infoWhoAllowed ?? '';
        this.newsletterListId = center?.newsletterListId ?? '';
        this.contacts = center?.contacts ?? [];
        this.cseContacts = center?.cseContacts ?? [];
        this.paypalClientId = center?.paypalClientId ?? '';
    }
}
