export const environment = {
  useEmulator: false,
  showClaimGift: true,
  cdn: 'les100ciels-dev.web.app',
  firebase: {
    apiKey: "AIzaSyCXdI6VN_IiK5gvzK5L7XnOZFEzdziNtQk",
    authDomain: "les100ciels-dev.firebaseapp.com",
    projectId: "les100ciels-dev",
    storageBucket: "les100ciels-dev.appspot.com",
    messagingSenderId: "843722076138",
    appId: "1:843722076138:web:42bdb1da22d0c576dd0c93"
  },
  production: false
};
