import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {ProductGroup as _ProductGroup} from '../../../../../core/model/product-group.model'
import {Product} from './product.model';

/**
 * Product Model
 */
export class ProductGroup extends _ProductGroup {

  products: Product[] = [];

  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): ProductGroup {
    return new ProductGroup({
      ...snapshot.data(options),
      id: snapshot.id,
      access: '',
      bePrepared: '',
      description: '',
      goodToKnow: '',
      longDescription: '',
      longDescription2: '',
      name: '',
    });
  }
}
