import {
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import { FirebaseFirestore } from '../../app.config'
import { Event } from '../model/event.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Event Service
 */
export class EventService {
  events = signal<Event[]>([]);
  private readonly eventsStateKey = makeStateKey<Event[]>('EVENTS');
  private transferState = inject(TransferState);
  private firestore = inject(FirebaseFirestore);

  /**
   * Load events from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let events: Event[] = [];
    if (this.transferState.hasKey(this.eventsStateKey)) {
      events = this.transferState.get(this.eventsStateKey, []).map(p => new Event(p));
    } else {
      const events = await this.firestore.getDocs('event', Event);
      events.sort((a, b) => a.index - b.index)
      this.transferState.set(this.eventsStateKey, events);
    }
    this.events.set(events);
  }
}
