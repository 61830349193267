import {CartItem, CartItemType, GiftCartItem, StandardCartItem} from './cart-item.model';

export type CartState = 'current' | 'cancelled' | 'abandoned';

export class Cart {
    items: CartItem[];
    lastModified: Date;
    state: CartState;
    promoCodes: { code: string, discount: number }[];
    giftCardCodes: { code: string, discount: number, lost: number }[];
    erpId: string;
    uid: string;
    centerId: string;
    price: number;
    taxIncluded: number;
    tax: number;

    constructor(cart?: Partial<Cart>) {
        this.lastModified = new Date();
        this.state = cart?.state ?? 'current';
        this.items = cart?.items?.map(i => {
            switch (i.type) {
                case CartItemType.Standard:
                    return new StandardCartItem(i);
                case CartItemType.Gift:
                    return new GiftCartItem(i);
            }
        }) ?? [];
        this.promoCodes = cart?.promoCodes ?? [];
        this.giftCardCodes = cart?.giftCardCodes ?? [];
        this.erpId = cart?.erpId ?? '';
        this.uid = cart?.uid ?? '';
        this.centerId = cart?.centerId ?? '';
        this.price = cart?.price ?? 0;
        this.taxIncluded = cart?.taxIncluded ?? 0;
        this.tax = cart?.tax ?? 0;
    }
}
