import { LocalEventType } from './event-type.model';
import { MediaDocument } from './media-document.model';

/**
 * Event Center Model
 */
export class Event {
  id: string;
  center: string
  index: number;
  name: string;
  title: string;
  description: string;
  longDescription: string;
  product: string;
  start: string;
  end: string;
  image: MediaDocument;
  link: string;
  type: LocalEventType;
  pageContent: string;

  /**
   * Construct category from a partial category object
   * @param event A Category like object
   */
  constructor(event?: Partial<Event>) {
    this.id = event?.id ?? '';
    this.center = event?.center ?? '';
    this.index = event?.index ?? 0;
    this.name = event?.name ?? '';
    this.title = event?.title ?? '';
    this.description = event?.description ?? '';
    this.longDescription = event?.longDescription ?? '';
    this.product = event?.product ?? '';
    this.start = event?.start ?? '';
    this.end = event?.end ?? '';
    this.image = new MediaDocument(event?.image);
    this.link = event?.link ?? '';
    this.type = event?.type ?? LocalEventType.EVENT_PRODUCT;
    this.pageContent = event?.pageContent ?? '';
  }
}
