import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {PageNotification as _PageNotification} from '../../../../../core/model/page-notification.model';

/**
 * Page Model
 */
export class PageNotification extends _PageNotification {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): PageNotification {
    return new PageNotification({
      ...snapshot.data(options),
      id: snapshot.id,
      title: '',
      content: ''
    });
  }
}
