import { MediaDocument } from './media-document.model';

export enum ProductType {
  Standard = 'Standard',
  GiftCertificate = 'GiftCertificate',
  ManagementFee = 'ManagementFee',
}

/**
 * Product Model
 */
export class Product {
  id: string;
  erpId: number;
  erpCode: string;
  linkedProducts: { erpCode: string, erpId: number }[];
  group: string;
  index: number;
  name: string;
  type: ProductType;
  enabled: boolean;
  quickPurchase: boolean;
  universes: { id: string, activities: string[] }[];
  categories: string[];
  slug: string;
  shortName: string;
  description: string;
  longDescription: string
  longDescription2: string
  goodToKnow: string;
  bePrepared: string;
  access: string;
  images: MediaDocument[];
  from: string;
  to: string;

  /**
   * Construct product from a partial product object
   * @param product A Product like object
   */
  constructor(product?: Partial<Product>) {
    this.id = product?.id ?? '';
    this.erpId = product?.erpId ?? 0;
    this.erpCode = product?.erpCode ?? '';
    this.linkedProducts = product?.linkedProducts ?? [];
    this.group = product?.group ?? '';
    this.index = product?.index ?? 0;
    this.name = product?.name ?? '';
    this.type = product?.type ?? ProductType.Standard;
    this.enabled = product?.enabled ?? true;
    this.quickPurchase = product?.quickPurchase ?? false;
    this.universes = product?.universes ?? [];
    this.categories = product?.categories ?? [];
    this.slug = product?.slug ?? '';
    this.shortName = product?.shortName ?? '';
    this.description = product?.description ?? '';
    this.longDescription = product?.longDescription ?? '';
    this.longDescription2 = product?.longDescription2 ?? '';
    this.goodToKnow = product?.goodToKnow ?? '';
    this.bePrepared = product?.bePrepared ?? '';
    this.access = product?.access ?? '';
    this.images = product?.images?.map(i => new MediaDocument(i)) ?? [];
    this.from = product?.from ?? '';
    this.to = product?.to ?? '';
  }
}
