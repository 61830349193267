import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Faq as _Faq} from '../../../../../core/model/faq.model';

/**
 * Page Model
 */
export class Faq extends _Faq {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Faq {
    const data = snapshot.data(options);
    return new Faq({
      ...data,
      id: snapshot.id,
      question: '',
      answer: '',
      centerAnswers: data['centerAnswers']?.map((ca: object) => ({
        ...ca,
        answer: ''
      })) ?? []
    });
  }
}

