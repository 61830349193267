import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID, Signal, signal, } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { filter, map, throttleTime, } from 'rxjs';
import { Center } from '../model/center.model';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Page Service
 */
export class NavigationService {
  navigationEnded: Signal<NavigationEnd | undefined> = signal(undefined);
  navigationStarted: Signal<NavigationStart | undefined> = signal(undefined);
  private siteService = inject(SiteService);
  private router = inject(Router);

  constructor() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      const router = inject(Router);
      this.navigationEnded = toSignal(router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          throttleTime(500, undefined, { leading: false, trailing: true }),
          map(e => e as NavigationEnd)
        )
      );
      this.navigationStarted = toSignal(router.events
        .pipe(
          filter(e => e instanceof NavigationStart),
          throttleTime(500, undefined, { leading: false, trailing: true }),
          map(e => e as NavigationStart)
        )
      );
    }
  }

  goTo(center: Center, path: string = '/', params?: Params): false {
    if (!center.enabled) {
      window.open(center.url + path);
      return false;
    }
    const toOtherCenter = center.id !== this.siteService.center()?.id;
    if (Capacitor.isNativePlatform()) {
      if (toOtherCenter) {
        this.siteService.center.set(center);
      }
      this.router.navigate([path], { queryParams: params, queryParamsHandling: 'merge' })
        .catch(err => console.error(err));
      return false;
    }
    if (toOtherCenter) {
      window.open(center.url + path + '?' + new URLSearchParams(params).toString());
    } else {
      this.router.navigate([path], { queryParams: params, queryParamsHandling: 'merge' })
        .catch(err => console.error(err));
    }
    return false;
  }
}
