/**
 * Center Model
 */
export class Category {
    id: string;
    index: number;
    name: string;
    icon: string;

    /**
     * Construct category from a partial category object
     * @param category A Category like object
     */
    constructor(category?: Partial<Category>) {
        this.id = category?.id ?? '';
        this.index = category?.index ?? 0;
        this.name = category?.name ?? '';
        this.icon = category?.icon ?? '';
    }
}
