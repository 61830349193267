import {
  computed,
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import {
  Firestore,
  getDocsFromCache,
  namedQuery,
} from '@angular/fire/firestore';
import { Category } from '../model/category.model';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Category Service
 */
export class CategoryService {
  categories = signal<Category[]>([]);
  private readonly categoriesStateKey = makeStateKey<Category[]>('CATEGORIES');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  private productService = inject(ProductService);
  ofCurrentCenter = computed(() => {
    const products = this.productService.ofCurrentCenter();
    return this.categories().filter(c => products.some(p => p.categories.includes(c.id)));
  })

  get(id: string): Category {
    return this.ofCurrentCenter().find(c => c.id === id) ?? new Category();
  }

  /**
   * Load universes from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let categories: Category[] = [];
    if (this.transferState.hasKey(this.categoriesStateKey)) {
      categories = this.transferState.get(this.categoriesStateKey, []).map(p => new Category(p));
    } else {
      const categoriesQuery = await namedQuery(this.firestore, 'categories');
      if (!categoriesQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(categoriesQuery.withConverter(Category));
      categories = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.categoriesStateKey, categories);

    }
    this.categories.set(categories);
  }
}
