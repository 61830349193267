import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Category as _Category} from '../../../../../core/model/category.model';

/**
 * Category Model
 */
export class Category extends _Category {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Category {
    return new Category({
      ...snapshot.data(options),
      id: snapshot.id
    });
  }
}
