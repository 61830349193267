import { isPlatformBrowser } from '@angular/common';
import { effect, inject, Injectable, PLATFORM_ID, signal, } from '@angular/core';
import { MediaDocument } from '../../../../../core/model/media-document.model';
import { FirebaseFirestore } from '../../app.config';
import { CenterService } from './center.service';
import { ProductService } from './product.service';
import { SelectionService } from './selection.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Service that contains current user selection
 */
export class SiteService {
  fmi = signal(0);
  private platformId = inject(PLATFORM_ID);
  private centerService = inject(CenterService);
  center = this.centerService.center;
  private productService = inject(ProductService);
  private selectionService = inject(SelectionService);
  private firestore = inject(FirebaseFirestore);

  constructor() {
    effect(() => {
      const center = this.center();
      this.selectionService.center.set(center);
      if (isPlatformBrowser(this.platformId)) {
        this.unsubscribeFmi();
        if (!center) {
          this.unsubscribeFmi = (): void => {
          };
          return;
        }
        this.firestore.onSnapshotDocument(`fmi/${String(center.erpId)}`,
          data => {
            this.fmi.set(data?.['fmi'] ?? 0)
          }).then(unsubscribe => this.unsubscribeFmi = unsubscribe)
      }
    }, { allowSignalWrites: true });
  }

  price(id: string): { price: number, fullPrice: number, promo: boolean } {
    const centerPrice = this.center()?.products.find(p => p.id === id);
    return {
      price: centerPrice?.price ?? 0,
      fullPrice: centerPrice?.fullPrice ?? 0,
      promo: centerPrice?.promo ?? false
    };
  }

  images(id: string): MediaDocument[] {
    const centerProduct = this.center()?.products.find(p => p.id === id);
    return centerProduct?.images.length ? centerProduct.images : this.productService.get(id).images;
  }

  index(id: string): number {
    return this.center()?.products.findIndex(p => p.id === id) ?? -1;
  }


  private unsubscribeFmi: () => void = () => {
  };
}
