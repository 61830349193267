import {MediaDocument} from './media-document.model';

/**
 * Product Model
 */
export class ProductGroup {
    id: string;
    erpId: number;
    erpCode: string;
    index: number;
    name: string;
    enabled: boolean;
    quickPurchase: boolean;
    universes: { id: string, activities: string[] }[];
    categories: string[];
    slug: string;
    description: string;
    longDescription: string
    longDescription2: string
    goodToKnow: string;
    bePrepared: string;
    access: string;
    images: MediaDocument[];

    /**
     * Construct product from a partial product object
     * @param productGroup A Product like object
     */
    constructor(productGroup?: Partial<ProductGroup>) {
        this.id = productGroup?.id ?? '';
        this.erpId = productGroup?.erpId ?? 0;
        this.erpCode = productGroup?.erpCode ?? '';
        this.index = productGroup?.index ?? 0;
        this.name = productGroup?.name ?? '';
        this.enabled = productGroup?.enabled ?? true;
        this.quickPurchase = productGroup?.quickPurchase ?? false;
        this.universes = productGroup?.universes ?? [];
        this.categories = productGroup?.categories ?? [];
        this.slug = productGroup?.slug ?? '';
        this.description = productGroup?.description ?? '';
        this.longDescription = productGroup?.longDescription ?? '';
        this.longDescription2 = productGroup?.longDescription2 ?? '';
        this.goodToKnow = productGroup?.goodToKnow ?? '';
        this.bePrepared = productGroup?.bePrepared ?? '';
        this.access = productGroup?.access ?? '';
        this.images = productGroup?.images?.map(i => new MediaDocument(i)) ?? [];
    }
}
