@if (style === 'transparent') {
  <div class="pointer-events-none fixed top-0 w-full h-full z-40 duration-{{duration}} bg-black"
       [ngClass]="displayed ? 'opacity-50' : 'opacity-0 -z-0'"
       aria-label="Background shadow"
       role="dialog" aria-modal="true">
  </div>
}

<div [ngClass]="displayed ? 'translate-y-0' : 'translate-y-full'"
     aria-label="Slide Over"
     aria-modal="true"
     class="fixed top-0 w-full h-full z-40 duration-{{duration}} rounded-3xl" role="dialog">
  <div class="fixed inset-0"></div>

  @if (style !== 'transparent') {
    <div class="border-t border-gray-200 absolute w-full h-2 z-20"
         [class.top-20]="style === 'white'"
         [class.top-32]="style === 'full'">
    </div>
  }

  <div class="pointer-events-none fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="fixed inset-y-0 right-0 flex max-w-full">
        <div class="w-screen">
          <div [class.bg-white]="['white'].includes(style)"
               class="h-full shadow-xl flex flex-col justify-center">
            @if (style === 'white') {
              <div class="pointer-events-auto px-4 py-6 sm:px-6 absolute top-0 left-0 z-40">
                <button type="button" (click)="component = null"
                        [attr.aria-label]="'close' | translate"
                        class="relative p-1 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <img ngSrc="assets/icons/dark/down.svg" class="w-6 h-6 rotate-90" width="11" height="11" alt="">
                </button>
              </div>
            }
            @if (title) {
              <div class="pointer-events-auto px-4 py-6 sm:px-6 absolute right-0 left-0 z-40 top-1 text-center">
                <h2 class="text-lg md:text-3xl font-semibold mb-14">{{ title | translate }}</h2>
              </div>
            }
            @if (style !== 'transparent') {
              <div class="pointer-events-auto px-4 py-6 sm:px-6 absolute right-0 z-40"
                   [class.top-0]="style === 'white'"
                   [class.top-32]="style === 'full'">
                <button type="button" (click)="component = null"
                        [attr.aria-label]="'close' | translate"
                        class="relative p-1 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor"
                       aria-hidden="true">
                    <path d="M6 18L18 6M6 6l12 12"/>
                  </svg>
                </button>
              </div>
            }
            @if (component) {
              <div class="pointer-events-auto h-screen overflow-y-auto"
                   [ngClass]="{
                   'mb-8 md:mb-0 mx-auto w-[240px]': socials,
                   'mb-8 md:mb-0 mx-[5%] md:mx-[20%]': style !== 'full' && !socials,
                   'mt-32': style === 'full',
                   }"

                   [class.mt-36]="style === 'white'"
                   [class.mt-8]="style === 'transparent'"
              >
                <ng-container *ngComponentOutlet="component.component; inputs: component.inputs"/>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
