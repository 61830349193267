import { inject } from '@angular/core';
import {
  getBytes,
  getDownloadURL,
  getMetadata,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';
import { FirebaseStorageInterface } from './storage.interface';

export class FirebaseStorageWeb implements FirebaseStorageInterface {
  storage = inject(Storage);

  async version(path: string): Promise<string> {
    const { generation } = await getMetadata(ref(this.storage, path));
    return generation;
  }

  download(path: string): Promise<ArrayBuffer> {
    return getBytes(ref(this.storage, path));
  }

  async upload(path: string, data: Blob): Promise<void> {
    await uploadBytes(ref(this.storage, path), data);
  }

  async url(path: string): Promise<string> {
    return getDownloadURL(ref(this.storage, path));
  }
}
