export enum Error {
  Unknown = 'unknown',
  AuthInternalError = 'auth/internal-error',
  AuthInvalidCredential = 'auth/invalid-credential',
  AuthEmailNotVerified = 'auth/email-not-verified',
  AuthPermissionDenied = 'auth/permission-denied',
  DataPermissionDenied = 'data/permission-denied',
  FirestoreError = 'firestore/query-error',
  AquaoEmailNotFound = 'aquao/email-not-found',
  AquaoCreateUserFailed = 'aquao/create-user-failed',
  AuthCreateUserFailed = 'auth/create-user-failed',
  AuthSignupIncompleteData = 'auth/signup-incomplete-data',
  AquaoVoucherClaimedBySelf = 'aquao/voucher-claimed-self',
  AquaoVoucherClaimedByOther = 'aquao/voucher-claimed-other',
  AquaoVoucherUsed = 'aquao/voucher-used',
  AquaoVoucherExpired = 'aquao/voucher-expired',
  AquaoVoucherCancelled = 'aquao/voucher-cancelled',
  AquaoVoucherWrongCenter = 'aquao/voucher-wrong-center',
}
