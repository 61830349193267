import { FirebaseStorage } from '@capacitor-firebase/storage';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { blobToBase64 } from '../../../shared/helper/file.helper';
import { FirebaseStorageInterface } from './storage.interface';

export class FirebaseStorageNative implements FirebaseStorageInterface {

  async version(path: string): Promise<string> {
    const { generation } = await FirebaseStorage.getMetadata({ path });
    return generation;
  }

  async download(path: string): Promise<string> {
    const { downloadUrl } = await FirebaseStorage.getDownloadUrl({ path });
    const response: HttpResponse = await CapacitorHttp.get({ url: downloadUrl });
    return response.data as string;
  }

  async upload(path: string, blob: Blob): Promise<void> {
    const base64 = await blobToBase64(blob);
    const fileName = 'profile';
    if (!base64) {
      return;
    }
    await Filesystem.writeFile({
      path: `${fileName}`,
      data:  base64.substr(base64.indexOf(',')+1),
      directory: Directory.Data
    });
    const uri = await Filesystem.getUri({
      directory: Directory.Data,
      path: `${fileName}`
    });
    return new Promise((resolve, reject) => {
      const mimeType = blob.type ?? 'image/jpeg';
      return FirebaseStorage.uploadFile(
        { path: path, uri: uri.uri, metadata: {
          contentType: mimeType, customMetadata: {}
        } },
        (event, error) => {
          if (error) {
            reject(error);
          } else if (event?.completed) {
            resolve();
          }
        }
      );
    });
  }

  async url(path: string): Promise<string> {
    const { downloadUrl } = await FirebaseStorage.getDownloadUrl({ path });
    return downloadUrl;
  }
}
