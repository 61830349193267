import { inject, Injectable, makeStateKey, signal, TransferState, } from '@angular/core';
import { Firestore, getDocsFromCache, namedQuery, } from '@angular/fire/firestore';
import { TranslateService } from '@tolgee/ngx';
import { Activity } from '../../../../../core/model/activity.model';
import { Universe } from '../model/universe.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Universe Service
 */
export class UniverseService {
  universes = signal<Universe[]>([]);
  private readonly universesStateKey = makeStateKey<Universe[]>('UNIVERSES');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  private translateService = inject(TranslateService);

  /**
   * Load universes from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let universes: Universe[] = [];
    if (this.transferState.hasKey(this.universesStateKey)) {
      universes = this.transferState.get(this.universesStateKey, []).map(p => new Universe(p));
    } else {
      const universesQuery = await namedQuery(this.firestore, 'universes');
      if (!universesQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(universesQuery.withConverter(Universe));
      universes = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.universesStateKey, universes);
    }

    this.universes.set(universes);
  }

  byName(name: string): Universe | null {
    return this.universes().find(u => u.name === name) ?? null
  }

  activity(activity: Activity, center: string | undefined): Activity {
    return new Activity({
      ...activity,
      ...activity.centerVariants.find(cld => cld.center === center)
    });
  }

  translate(centerId: string | undefined, universeId: string, activityId: string, key: string): string {
    let tentative = '';
    if (centerId) {
      tentative =
        this.translateService.instant(
          `universe/${universeId}/activity/${activityId}/center/${centerId}/${key}`, { noWrap: true, orEmpty: true });
    }
    return tentative ||
      this.translateService.instant(`universe/${universeId}/activity/${activityId}/${key}`, { noWrap: true });
  }
}
