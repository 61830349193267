import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Order as _Order} from '../../../../../core/model/order.model';

/**
 * Order Model
 */
export class Order extends _Order {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Order {
    const data = snapshot.data(options);
    return new Order({
      id: snapshot.id,
      ...data,
      timestamp: data['timestamp'] ? new Date(data['timestamp'].seconds * 1000) : new Date(),
    });
  }
}
