import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Page as _Page} from '../../../../../core/model/page.model';

/**
 * Page Model
 */
export class Page extends _Page {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Page {
    return new Page({
      ...snapshot.data(options),
      id: snapshot.id
    });
  }
}
