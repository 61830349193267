import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from '@angular/fire/firestore';
import { Press as _Press } from '../../../../../../core/model/global/press.model'

export class Press extends _Press {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Press {
    return new Press({
      ...snapshot.data(options)
    });
  }
}
