import {
  Plugin,
  type PluginListenerHandle,
  registerPlugin
} from '@capacitor/core';
import { FirebaseUser } from './firebase/auth/auth.interface';

export interface FirebaseFunctions {
  httpsCallable<T, U>(options: { fn: string, payload: T }): Promise<{ result: U }>;
}

export const FirebaseFunctions = registerPlugin<FirebaseFunctions>('FirebaseFunctions');

export interface FirebaseAuthenticationExtended extends Plugin {
  checkActionCode(options: { oobCode: string }): Promise<{ email: string | null }>;

  addListener(eventName: 'idTokenChange',
              listenerFunc: (change: { user: FirebaseUser }) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  verifyPasswordResetCode(options: { oobCode: string }): Promise<{ email: string }>;
}

export const FirebaseAuthenticationExtended =
  registerPlugin<FirebaseAuthenticationExtended>('FirebaseAuthenticationExtended');
