import { inject } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc as _getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
  WhereFilterOp
} from '@angular/fire/firestore';
import { FirestoreDataConverter } from '@firebase/firestore';
import { FirebaseFirestoreInterface, } from './firestore.interface';

export class FirebaseFirestoreWeb implements FirebaseFirestoreInterface {
  firestore = inject(Firestore);

  async getDoc<T, U extends DocumentData>(
    path: string,
    _class: { new(t?: Partial<T>): T } & FirestoreDataConverter<T, U>
  ): Promise<T | undefined> {
    const snap = await _getDoc(doc(this.firestore, path).withConverter(_class));
    return snap.data();
  }

  async setDoc<T, U extends DocumentData>(
    path: string,
    data: T,
    _class: FirestoreDataConverter<T, U>
  ): Promise<void> {
    await setDoc(doc(this.firestore, path).withConverter(_class), data);
  }

  async deleteDoc(path: string): Promise<void> {
    await deleteDoc(doc(this.firestore, path));
  }

  async getDocs<T, U extends DocumentData>(
    path: string,
    _class: { new(t?: Partial<T>): T } & FirestoreDataConverter<T, U>,
    queryContraints?: { fieldPath: string, opStr: WhereFilterOp, value: unknown }[],
  ): Promise<T []> {
    const q = queryContraints ?
      query(
        collection(this.firestore, path),
        ...queryContraints.map(({ fieldPath, opStr, value }) =>
          where(fieldPath, opStr, value))
      ) :
      query(collection(this.firestore, path))
    const snapshot = await getDocs(q.withConverter(_class));
    return snapshot.docs.map(doc => doc.data());
  }

  async onSnapshotDocument(path: string, callback: (snapshot: any) => void): Promise<() => void> {
    return onSnapshot(doc(this.firestore, path), snapshot => {
      callback(snapshot.data());
    });
  }

  async onSnapshotCollection(path: string, callback: (snapshot: any) => void): Promise<() => void> {
    return onSnapshot(collection(this.firestore, path), snapshot => {
      callback(snapshot.docs.map(doc => doc.data()));
    });
  }
}
