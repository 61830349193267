import { Template } from '../../template';

/**
 * Common data across all sites
 */
export class Settings {
  contacts: string[];
  mailTemplates: Record<Template, string>;

  /**
   * Construct settings from a partial settings object
   * @param settings A Settings like object
   */
  constructor(settings?: Partial<Settings>) {
    this.contacts = settings?.contacts ?? [];
    this.mailTemplates = Object.fromEntries(
      Object.values(Template).map(t => [ t, settings?.mailTemplates?.[t] ?? '' ])
    ) as Record<Template, string>;
  }
}
