import { app } from 'firebase-admin';
import { FirebaseStorageInterface } from './storage.interface';

export class FirebaseStorageServer implements FirebaseStorageInterface {
  private admin: app.App;

  constructor(admin: app.App) {
    this.admin = admin;
  }

  async version(path: string): Promise<string> {
    const [ metadata ] = await this.admin.storage().bucket().file(path).getMetadata();
    return String(metadata.generation);
  }

  async download(path: string): Promise<ArrayBuffer> {
    const [ buffer ] = await this.admin.storage().bucket().file(path).download();
    return buffer;
  }

  async upload(path: string, data: Blob): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async url(path: string): Promise<string> {
    return this.admin.storage().bucket().file(path).publicUrl();
  }
}
