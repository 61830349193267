import {
  Injectable,
  signal,
} from '@angular/core';
import { Center } from '../model/center.model';
import { ProductGroup } from '../model/product-group.model';
import { Product } from '../model/product.model';
import { Universe } from '../model/universe.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Service that contains current user selection
 */
export class SelectionService {
  center = signal<Center | null>(null);
  universe = signal<Universe | null>(null);
  product = signal<Product | ProductGroup | null>(null);
}
