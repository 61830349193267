import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';
import { Activity } from '../../../../../core/model/activity.model';
import { Universe as _Universe } from '../../../../../core/model/universe.model';

/**
 * Universe Model
 */
export class Universe extends _Universe {

  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Universe {
    const data = snapshot.data(options);
    return new Universe({
      ...data,
      id: snapshot.id,
      activities: data['activities'].map((a: Activity) => ({
        ...a,
        title: '',
        description: '',
        longDescription: '',
        centerVariants: a.centerVariants?.map(ca => ({
          ...ca,
          longDescription: ''
        })) ?? []
      }))
    });
  }
}
