/**
 * Faq Model
 */
export class Faq {
    id: string;
    index: number;
    category: string;
    question: string;
    answer: string;
    centerAnswers: { center: string, answer: string }[]

    /**
     * Construct faq from a partial faq object
     * @param faq A Faq like object
     */
    constructor(faq?: Partial<Faq>) {
        this.id = faq?.id ?? '';
        this.index = faq?.index ?? 0;
        this.category = faq?.category ?? '';
        this.question = faq?.question ?? '';
        this.answer = faq?.answer ?? '';
        this.centerAnswers = faq?.centerAnswers ?? [];
    }
}
