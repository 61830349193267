import {
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import {
  Firestore,
  getDocsFromCache,
  namedQuery,
} from '@angular/fire/firestore';
import { Page } from '../model/page.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Page Service
 */
export class PageService {
  private readonly pagesStateKey = makeStateKey<Page[]>('PAGE');
  private transferState = inject(TransferState);
  private firestore = inject(Firestore);
  pages = signal<Page[]>([]);

  /**
   * Load pages from bundle
   * @returns A Promise
   */
  async init(): Promise<void> {
    let pages: Page[] = [];
    if (this.transferState.hasKey(this.pagesStateKey)) {
      pages = this.transferState.get(this.pagesStateKey, []).map(p => new Page(p));
    } else {
      const pagesQuery = await namedQuery(this.firestore, 'page');
      if (!pagesQuery) {
        return;
      }
      const snapshot = await getDocsFromCache(pagesQuery.withConverter(Page));
      pages = snapshot.docs.map(doc => doc.data());
      this.transferState.set(this.pagesStateKey, pages);
    }

    this.pages.set(pages);
  }
}
