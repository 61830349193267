import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Product as _Product} from '../../../../../core/model/product.model'

/**
 * Product Model
 */
export class Product extends _Product {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Product {
    return new Product({
      ...snapshot.data(options),
      id: snapshot.id,
      access: '',
      bePrepared: '',
      description: '',
      goodToKnow: '',
      longDescription: '',
      longDescription2: '',
      shortName: '',
      name: '',
    });
  }
}
