<div aria-live="assertive"
     class="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
  <div class="flex w-full flex-col items-center justify-center space-y-4 sm:items-end">
    @for (notification of notifications; track notification) {
      <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg
                      ring-1 ring-black ring-opacity-5 transform transition"
           [ngClass]=" {
           'translate-y-0 opacity-100 sm:translate-x-0': notification.__animation === null,
           'ease-out duration-300 translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2': notification.__animation === 'enter',
           'ease-in duration-100 opacity-0': notification.__animation === 'leave',
           }">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              @switch (notification.type) {
                @case ('message') {
                  <svg class="h-6 w-6" [class.mt-2]="!notification.title" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor"
                       aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0
                                 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
                  </svg>
                }
                @case ('success') {
                  <svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" [class.mt-2]="!notification.title"
                       stroke="currentColor"
                       aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                }
                @case ('warning') {
                  <svg class="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" [class.mt-2]="!notification.title"
                       stroke="currentColor"
                       aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                  </svg>
                }
                @case ('error') {
                  <svg class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" [class.mt-2]="!notification.title"
                       stroke="currentColor"
                       aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>

                }
              }
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">{{ notification.title | translate }}</p>
              <div class="flex flex-col gap-1">
                <p
                  class="mt-1 text-sm text-gray-500 grow">{{ notification.message | translate: notification.interpolateParams }}</p>
                @if (notification.button) {
                  <button [appProgressClick]="notification.button.action"
                          appHoverGradient
                          type="button"
                          class="rounded bg-100ciels-300 px-2 py-1 text-sm font-semibold text-blue-50 shadow-sm">
                    {{ notification.button.label | translate }}
                  </button>
                }
              </div>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button type="button"
                      (click)="close(notification)"
                      (keyup.enter)="emulateClick($event)"
                      class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none
                                 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">{{ 'close' | translate }}</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72
                           3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

    }
  </div>
</div>
