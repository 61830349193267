import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from '@angular/fire/firestore';
import { Settings as _Settings } from '../../../../../../core/model/global/settings.model'

export class Settings extends _Settings {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Settings {
    return new Settings({
      ...snapshot.data(options)
    });
  }
}
