import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from '@angular/fire/firestore';
import { Landing as _Landing } from '../../../../../../core/model/global/landing.model'

export class Landing extends _Landing {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Landing {
    return new Landing({
      ...snapshot.data(options)
    });
  }
}
