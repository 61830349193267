import { ApplicationRef, inject, } from '@angular/core';
import { Analytics, isSupported, logEvent as _logEvent, setConsent, } from '@angular/fire/analytics';
import { FirebaseAnalyticsInterface } from './analytics.interface';

declare global {
  interface Window {
    gtag(type: 'event', eventName: string, eventParams: Record<string, unknown>): void
  }
}

export class FirebaseAnalyticsWeb implements FirebaseAnalyticsInterface {
  analytics: Analytics | null = null;
  private applicationRef = inject(ApplicationRef)
  private initialized = false;

  async init(): Promise<void> {
    if (await isSupported()) {
      this.analytics = this.applicationRef.injector.get(Analytics)
      setConsent({
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      })
    }
  }

  async logEvent(eventName: string, eventParams: Record<string, unknown>): Promise<void> {
    if (!this.initialized) {
      await this.init();
      this.initialized = true;
    }

    if (eventParams['send_to']) {
      window.gtag('event', eventName, eventParams);
      return Promise.resolve();
    }

    if (!this.analytics) {
      return Promise.resolve();
    }
    _logEvent(this.analytics, eventName, eventParams);
    return Promise.resolve();
  }
}
