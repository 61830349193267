import { inject } from '@angular/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { NotificationService } from '../../../notification/notification.service';
import { FirebaseAuthenticationExtended } from '../../capacitor.plugin';
import {
  FirebaseAuthInterface,
  FirebaseUser
} from './auth.interface';

export class FirebaseAuthNative implements FirebaseAuthInterface {
  private notification = inject(NotificationService);

  async applyActionCode(oobCode: string): Promise<void> {
    await FirebaseAuthentication.applyActionCode({ oobCode });
  }

  async checkActionCode(oobCode: string): Promise<string | null> {
    const { email } = await FirebaseAuthenticationExtended.checkActionCode({ oobCode });
    return email;
  }

  async confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    await FirebaseAuthentication.confirmPasswordReset({ oobCode, newPassword });
  }

  async getIdToken(forceRefresh: boolean = false): Promise<string | null> {
    const { token } = await FirebaseAuthentication.getIdToken({ forceRefresh });
    return token ?? null;
  }

  async linkWithEmailAndPassword(email: string, password: string): Promise<void> {
    await FirebaseAuthentication.linkWithEmailAndPassword({ email, password });
  }

  onIdTokenChanged(fn: (user: FirebaseUser) => void): void {
    FirebaseAuthenticationExtended.addListener('idTokenChange', ({ user }) => fn(user));
  }

  async signInAnonymously(): Promise<void> {
    await FirebaseAuthentication.signInAnonymously();
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<void> {
    await FirebaseAuthentication.signInWithEmailAndPassword({ email, password });
  }

  async signInWithApple(): Promise<void> {
    await FirebaseAuthentication.signInWithApple();
  }

  async signInWithGoogle(): Promise<void> {
    await FirebaseAuthentication.signInWithGoogle({ mode: 'redirect' });
  }

  async signInWithFacebook(): Promise<void> {
    try {
      await FirebaseAuthentication.signInWithFacebook({ mode: 'redirect' });
    } catch (err) {
      // @ts-expect-error Capacitor error always contains a code
      if (err.code !== 'account-exists-with-different-credential') {
        this.notification.open({ type: 'error', title: 'error', message: (err as Error).message });
        return;
      }
      this.notification.open({
        type: 'message',
        title: 'accountExistsWithDifferentCredential',
        message: 'accountExistsWithDifferentCredentialNative'
      });
    }
  }

  async signOut(): Promise<void> {
    await FirebaseAuthentication.signOut();
  }

  async verifyPasswordResetCode(oobCode: string): Promise<string> {
    const { email } = await FirebaseAuthenticationExtended.verifyPasswordResetCode({ oobCode });
    return email;
  }

  async getRedirectResult(): Promise<void> {
    return Promise.resolve();
  }
}
