import {
  DOCUMENT,
  isPlatformBrowser,
} from '@angular/common';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { NotificationsComponent } from './notifications.component';

export type NotificationType = 'message' | 'success' | 'warning' | 'error';

export type Notification = {
  type: NotificationType,
  title: string,
  message: string,
  interpolateParams?: Record<string, string>,
  button?: { action: () => Promise<unknown>, label: string } | null,
  __animation?: 'enter' | 'leave' | null
};

@Injectable({
  providedIn: 'root',
})
/**
 * Notification service
 */
export class NotificationService {
  private applicationRef = inject(ApplicationRef);
  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);
  private readonly container: ComponentRef<NotificationsComponent> | null = null;

  /**
   * Create the Notification container and attach it to the app
   */
  constructor() {
    // TODO: Inject Notification container Component server side and,
    //  browser side, retrieve it if it exists and inject it
    if (isPlatformBrowser(this.platformId)) {
      this.container = createComponent(
        NotificationsComponent,
        {
          environmentInjector: this.applicationRef.injector,
          hostElement: this.document.getElementById('notification-host')!
        });
      this.applicationRef.attachView(this.container.hostView);
      this.container.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Open a notification
   * @param options Notification options
   * @param options.type - The type of notification
   * @param options.title - The title to display
   * @param options.message - The message to display
   * @param options.interpolateParams - Parameters pass to translation service
   * @param options.button - Options for an action button
   * @param options.close - Delay before autoclosing
   */
  open(options: {
         type: NotificationType,
         title: string,
         message: string,
         interpolateParams?: Record<string, string>,
         button?: { action: () => Promise<unknown>, label: string } | null,
         close?: number
       }
  ): void {
    this.container?.instance.open(options);
  }
}
