import { DOCUMENT, isPlatformBrowser, isPlatformServer, } from '@angular/common';
import { inject, Injectable, isDevMode, PLATFORM_ID, REQUEST, RESPONSE_INIT, } from '@angular/core';

type SameSite = 'Lax' | 'None' | 'Strict';

type Options = {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: SameSite;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Cookie service
 */
export class CookieService {
  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);
  private request = inject(REQUEST, { optional: true });
  private response = inject(RESPONSE_INIT, { optional: true });

  private cookieString = isPlatformBrowser(this.platformId) ?
    this.document.cookie :
    (this.request?.headers.get('cookie') ?? '');

  // https://firebase.google.com/docs/hosting/manage-cache#using_cookies
  private authCookieName = '__session';

  /**
   * Get cookie Regular Expression
   * @param name Cookie name
   * @returns The property RegExp
   */
  static getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(/([[\]{}()|=;+?,.*^$])/gi, '\\$1');
    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }

  /**
   * Gets the un-encoded version of an encoded component of a Uniform Resource Identifier (URI).
   * @param encodedURIComponent A value representing an encoded URI component.
   * @returns The un-encoded version of an encoded component of a Uniform Resource Identifier (URI).
   */
  static safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      // probably it is not uri encoded. return as is
      return encodedURIComponent;
    }
  }

  /**
   * Return `true` if {@link Document} is accessible, otherwise return `false`
   * @param name The cookie name
   * @returns Whether cookie with specified name exists
   */
  check(name: string): boolean {
    if (!this.cookieString) {
      return false;
    }
    const encodedName = encodeURIComponent(name);
    const regExp: RegExp = CookieService.getCookieRegExp(encodedName);
    return regExp.test(this.cookieString);
  }

  /**
   * Get cookies by name
   * @param name The cookie name
   * @returns The property value
   */
  get(name: string): string {
    if (!this.cookieString) {
      return '';
    }
    if (!this.check(name)) {
      return '';
    }
    const encodedName = encodeURIComponent(name);
    const regExp: RegExp = CookieService.getCookieRegExp(encodedName);
    const result = regExp.exec(this.cookieString);
    return result?.[1] ? CookieService.safeDecodeURIComponent(result[1]) : '';
  }

  /**
   * Get all cookies in JSON format
   * @returns All the cookies in json
   */
  getAll(): { [key: string]: string } {
    if (!this.cookieString) {
      return {};
    }
    return this.cookieString.split(';')
      .reduce((cookies, cookie) => {
        const [cookieName, cookieValue] = cookie.split('=');
        return {
          ...cookies,
          [CookieService.safeDecodeURIComponent(cookieName.replace(/^ /, ''))]:
            CookieService.safeDecodeURIComponent(cookieValue),
        };
      }, {});
  }

  /**
   * Set cookie based on provided information
   * @param name Cookie name
   * @param value Cookie value
   * @param expires Number of days until the cookies expires or an actual `Date`
   * @param path Cookie path
   * @param domain Cookie domain
   * @param secure Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   */
  set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean,
      sameSite?: SameSite
  ): void;

  /**
   * Set cookie based on provided information
   *
   * Cookie's parameters:
   * <pre>
   * expires  Number of days until the cookies expires or an actual `Date`
   * path     Cookie path
   * domain   Cookie domain
   * secure   Secure flag
   * sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   * </pre>
   * @param name Cookie name
   * @param value Cookie value
   * @param options Body with cookie's params
   */
  set(
    name: string,
    value: string,
    options?: Options
  ): void;

  set(
    name: string,
    value: string,
    expiresOrOptions?: number | Date | Options,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: SameSite,
  ): void {
    const isNumber = typeof expiresOrOptions === 'number';
    const isDate = expiresOrOptions instanceof Date;
    if (isNumber || isDate || path || domain || secure || sameSite) {
      const optionsBody: Options = {
        expires: expiresOrOptions as (number | Date),
        path,
        domain,
        secure,
        sameSite: sameSite ?? 'Lax',
      };

      this.set(name, value, optionsBody);
      return;
    }

    const options = expiresOrOptions ?? {};

    if (isPlatformServer(this.platformId)) {
      const cookiesSettings = [`${name}=${value}`];
      if (options.expires) {
        cookiesSettings.push(`Expires=${options.expires}`)
      }
      if (options.domain) {
        cookiesSettings.push(`Domain=${options.domain}`)
      }
      if (options.path) {
        cookiesSettings.push(`Path=${options.path}`)
      }
      if (options.secure) {
        cookiesSettings.push(`Secure`)
      }
      if (options.sameSite) {
        cookiesSettings.push(`SameSite=${options.sameSite}`)
      }

      const headers = this.response?.headers;
      if (headers) {
        if (headers instanceof Headers) {
          headers.set('Set-Cookie', cookiesSettings.join('; '));
        } else if (Array.isArray(headers)) {
          headers.push(['Set-Cookie', cookiesSettings.join('; ')]);
        } else {
          headers['Set-Cookie'] = cookiesSettings.join('; ');
        }
      }
      return;
    }

    let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    if (options.expires) {
      if (typeof options.expires === 'number') {
        const dateExpires: Date = new Date(new Date().getTime() + options.expires * 1000 * 60 * 60 * 24);

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + options.expires.toUTCString() + ';';
      }
    }

    if (options.path) {
      cookieString += 'path=' + options.path + ';';
    }

    if (options.domain) {
      cookieString += 'domain=' + options.domain + ';';
    }

    if (options.secure === false && options.sameSite === 'None') {
      options.secure = true;
    }
    if (options.secure) {
      cookieString += 'secure;';
    }

    if (!options.sameSite) {
      options.sameSite = 'Lax';
    }

    cookieString += 'sameSite=' + options.sameSite + ';';

    this.document.cookie = cookieString;
  }

  /**
   * Delete cookie by name
   * @param name Cookie name
   * @param path Cookie path
   * @param domain Cookie domain
   * @param secure Cookie secure flag
   * @param sameSite Cookie sameSite flag
   */
  delete(name: string, path?: string, domain?: string, secure?: boolean, sameSite: SameSite = 'Lax'): void {
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
    this.set(name, '', { expires: expiresDate, path, domain, secure, sameSite });
  }

  /**
   * Delete all cookies
   * @param path Cookie path
   * @param domain Cookie domain
   * @param secure Is the Cookie secure
   * @param sameSite Is the cookie same site
   */
  deleteAll(path?: string, domain?: string, secure?: boolean, sameSite: SameSite = 'Lax'): void {
    const cookies = this.getAll();

    for (const cookieName of Object.values(cookies)) {
      this.delete(cookieName, path, domain, secure, sameSite);
    }
  }

  /**
   * Get the auth cookie
   * @returns The auth cookie
   */
  getAuth(): string {
    return this.get(this.authCookieName);
  }

  /**
   * Set the auth cookie
   * @param token The cookie to set
   */
  setAuth(token: string): void {
    this.set(this.authCookieName, token, { path: '/', sameSite: 'Lax', secure: !isDevMode() });
  }
}
