import {
  effect,
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
  WritableSignal,
} from '@angular/core';

export type Header = 'full' | 'plain' | 'minimal';

@Injectable({
  providedIn: 'root',
})
/**
 * Service that contains current user selection
 */
export class HeaderService {
  private readonly HeaderStateKey = makeStateKey<Header>('HEADER');
  private readonly SearchStateKey = makeStateKey<boolean>('SEARCH');
  private transferState = inject(TransferState);
  type: WritableSignal<Header>;
  search: WritableSignal<boolean>;

  constructor() {
    this.type = signal(this.transferState.get(this.HeaderStateKey, 'minimal'));
    effect(() => {
      this.transferState.set(this.HeaderStateKey, this.type())
    });
    this.search = signal(this.transferState.get(this.SearchStateKey, false));
    effect(() => {
      this.transferState.set(this.SearchStateKey, this.search())
    });
  }


}
