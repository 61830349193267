import { isPlatformBrowser } from '@angular/common';
import { computed, effect, inject, Injectable, PLATFORM_ID, signal, } from '@angular/core';
import { CartItem, GiftCartItem, StandardCartItem } from '../../../../../core/model/cart/cart-item.model';
import { PaymentResponse } from '../../../../../core/model/up2pay-response.model';
import { FirebaseFirestore, FirebaseFunctions } from '../../app.config';
import { Cart } from '../model/cart.model';
import { Order } from '../model/order.model';
import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';
import { SiteService } from './site.service';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Cart Service
 */
export class CartService {
  saved = signal<boolean>(false);
  cart = signal<Cart>(new Cart());
  total = computed(() => {
    const cart = this.cart();
    const lostValue = cart.giftCardCodes
      .reduce((sum, curr) => sum + (curr.lost ?? 0), 0);
    return cart.taxIncluded - lostValue;
  });
  taxes = computed(() => (this.cart().tax));
  toPay = computed(() => this.cart().price)
  numberOfItems = computed(() => this.cart().items.length);
  hasPromoCodes = computed(() => this.cart().promoCodes.length > 0);
  hasGiftCardCodes = computed(() => this.cart().giftCardCodes.length > 0);
  syncInProgress = signal(true);
  lastCartSync = signal<Date | null>(null);
  saving = computed(() => !this.saved() || this.syncInProgress())
  empty = computed(() => !this.cart().items.length)
  private functions = inject(FirebaseFunctions);
  private firestore = inject(FirebaseFirestore);
  private authService = inject(AuthService);
  private siteService = inject(SiteService);
  private productService = inject(ProductService);
  private analyticsService = inject(AnalyticsService);

  constructor() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      effect(async () => {
        const user = this.authService.user();
        const center = this.siteService.center()
        if (!user?.uid || !center) {
          return;
        }
        this.unsubscribeCart();

        const transferCart = localStorage.getItem('transferCart');
        if (transferCart && transferCart !== user.uid) {
          await this.copy(transferCart, user.uid, center.id);
        }
        localStorage.removeItem('transferCart');

        this.unsubscribeCart = await this.firestore.onSnapshotDocument(
          `user/${user.uid}/carts/current-${center.id}`, doc => {
            const cart = new Cart({
              ...doc,
              items: doc?.items ?? [],
              lastModified: doc?.['lastModified'] ? new Date(doc['lastModified'].seconds * 1000) : new Date(),
            });
            const filtered = new Cart({
              ...cart,
              items: cart.items.filter(i => this.productService.availableForCurrentCenter(i.product))
            });
            this.cart.set(filtered);
            if (cart.items.length === filtered.items.length) {
              this.lastCartSync.set(new Date());
            } else {
              this.save();
            }
          });
      });
      effect(async () => {
        const user = this.authService.user();
        if (!user?.uid) {
          return;
        }
        this.unsubscribeTasks();
        this.unsubscribeTasks = await this.firestore.onSnapshotCollection(
          `user/${user.uid}/tasks`, (tasks: []) => {
            this.syncInProgress.set(!!tasks.length)
          })
      });
    }
  }

  unsubscribeCart = (): void => {
  };

  unsubscribeTasks = (): void => {
  };

  /**
   * Copy cart between two user uid
   * @param fromUid The anonymous user id
   * @param toUid The authenticated user id
   * @param centerId The center id
   * @returns The promise
   */
  async copy(fromUid: string, toUid: string, centerId: string): Promise<void> {
    return this.functions.httpsCallable<{ fromUid: string, toUid: string, centerId: string }, void>
    ('cart-transfer', { fromUid, toUid, centerId });
  }

  async refreshCart(): Promise<void> {
    this.saved.set(false);
    try {
      await this.updateCart();
      this.saved.set(true);
    } catch (err) {
      console.error(err);
    }
  }

  async save(): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    await this.firestore
      .setDoc(`user/${user.uid}/carts/current-${this.siteService.center()!.id}`, this.cart(), Cart);
  }

  async delete(): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    await this.firestore.deleteDoc(`user/${user.uid}/carts/current-${this.siteService.center()!.id}`);
  }

  async add(items: { cartItem: CartItem, quantity?: number }[], after?: CartItem): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    const cartItems = items
      .filter(item => item.cartItem.product)
      .flatMap(item => Array(item.quantity ?? 1).fill(item.cartItem))
    if (!cartItems.length) {
      return;
    }

    this.cart.update(cart => {
      const items = cart.items;
      let index = after ? items.indexOf(after) : items.length;
      if (index < 0) {
        index = items.length;
      }
      return new Cart({
        ...cart,
        items: [...items.slice(0, index), ...cartItems, ...items.slice(index)]
      });
    });
    await this.save();
    this.analyticsService.addToCart(items);
    if (after) {
      await this.refreshCart();
    }
  }

  async remove(cartItem: CartItem): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    this.cart.update(cart => {
      const idx = cart.items.indexOf(cartItem);
      if (idx < 0) {
        return new Cart(cart);
      }
      cart.items.splice(idx, 1);
      return new Cart({
        ...cart,
        items: [...cart.items]
      });
    });
    await this.save();
    this.analyticsService.removeFromCart([{ cartItem }]);
    await this.refreshCart();
  }


  async update(previous: CartItem, current: CartItem): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    this.cart.update(cart => {
      const idx = cart.items.indexOf(previous);
      if (idx < 0) {
        return new Cart(cart)
      }
      cart.items[idx] = current;
      return new Cart({
        ...cart,
        items: [...cart.items]
      });
    });
    await this.save();
  }

  async switchGift(cartItem: CartItem): Promise<void> {
    const user = this.authService.user();
    if (!user?.uid) {
      return;
    }
    this.cart.update(cart => {
      const idx = cart.items.indexOf(cartItem);
      if (idx < 0) {
        return new Cart(cart)
      }
      const newItem = cartItem instanceof GiftCartItem ? new StandardCartItem(cartItem) : new GiftCartItem(cartItem);
      cart.items.splice(idx, 1, newItem);
      return new Cart({
        ...cart,
        items: [...cart.items]
      });
    });
    await this.save();
  }

  /**
   * Call API to get a payment link
   * @returns The promise
   */
  async getPaymentLink(): Promise<PaymentResponse> {
    this.analyticsService.beginCheckout(this.cart().items.map(cartItem => ({ cartItem })));
    return this.functions.httpsCallable<{ centerId: string, paypal: boolean }, PaymentResponse>
    ('payment-getPaymentLink', { centerId: `${this.siteService.center()?.id}`, paypal: false });
  }

  /**
   * Call API to get a payment link
   * @returns The promise
   */
  async getPaymentLinkPaypal(): Promise<{ orderId: string, amount: number }> {
    this.analyticsService.beginCheckout(this.cart().items.map(cartItem => ({ cartItem })));
    return this.functions.httpsCallable<{ centerId: string, paypal: boolean }, { orderId: string, amount: number }>
    ('payment-getPaymentLink', { centerId: `${this.siteService.center()?.id}`, paypal: true });
  }

  /**
   * Call API to get a payment link
   * @param paymentId The payment id
   * @param amount The payment amount
   * @param error The error code
   * @param paypal paypal payment
   * @returns The promise
   */
  async pay(paymentId: string, amount: string, error: string, paypal: boolean): Promise<void> {
    return this.functions.httpsCallable<{ paymentId: string, amount: string, error: string, paypal: boolean }, void>
    ('payment-pay', { paymentId, amount, error, paypal });
  }

  /**
   * Add a promotion code into cart
   * @param promotionCode The new promotion code
   */
  async addPromotionCode(promotionCode: string): Promise<void> {
    const codes = this.cart().promoCodes.filter(pc => pc.code !== promotionCode);
    codes.push({ code: promotionCode, discount: 0 });
    this.cart().promoCodes = codes;
    await this.save();
  }

  /**
   * Remove a promotion code into cart
   * @param promotionCode The promotion code to remove
   */
  async removePromotionCode(promotionCode: string): Promise<void> {
    this.cart().promoCodes = this.cart().promoCodes.filter(pc => pc.code !== promotionCode);
    await this.save();
  }

  /**
   * Add a gift card into cart
   * @param giftVoucherCode The new promotion code
   */
  async addGiftCard(giftVoucherCode: string): Promise<void> {
    const codes = this.cart().giftCardCodes.filter(gc => gc.code != giftVoucherCode);
    codes.push({ code: giftVoucherCode, discount: 0, lost: 0 });
    this.cart().giftCardCodes = codes;
    await this.save();
  }

  /**
   * Remove a gift card into cart
   * @param giftVoucherCode The promotion code to remove
   */
  async removeGiftCard(giftVoucherCode: string): Promise<void> {
    this.cart().giftCardCodes = this.cart().giftCardCodes.filter(gc => gc.code !== giftVoucherCode);
    await this.save();
  }

  restoreCart(paymentId: string): Promise<void> {
    return this.functions.httpsCallable<{ paymentId: string }, void>
    ('cart-restoreCart', { paymentId });
  }

  async getPurchase(paymentId: string): Promise<Order | null> {
    const orders = await this.firestore.getDocs(
      `user/${this.authService.user()!.uid}/orders`,
      Order,
      [{ fieldPath: 'erpId', opStr: '==', value: paymentId }]
    );
    return orders[0] ?? null;
  }

  /**
   * Call API to get a payment link
   * @returns The promise
   */
  private async updateCart(): Promise<PaymentResponse> {
    return this.functions.httpsCallable<{ centerId: string }, PaymentResponse>
    ('cart-syncCart', {
      centerId: this.siteService.center()?.id ?? ''
    });
  }
}
