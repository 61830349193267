import { inject } from '@angular/core';
import {
  Functions,
  httpsCallable
} from '@angular/fire/functions';
import { FirebaseFunctionsInterface } from './functions.interface';

export class FirebaseFunctionsWeb implements FirebaseFunctionsInterface {
  functions = inject(Functions);

  async httpsCallable<T, U>(fn: string, payload: T): Promise<U> {
    const { data } = await httpsCallable<T, U>(this.functions, fn)(payload);
    return data;
  }

}
