import { isPlatformBrowser } from '@angular/common';
import { computed, inject, Injectable, PLATFORM_ID, signal, } from '@angular/core';
import type { User as WebAuthUser } from '@angular/fire/auth';
import { Router } from '@angular/router';
import type { User as NativeAuthUser } from '@capacitor-firebase/authentication';
import { jwtDecode } from 'jwt-decode';
import { Error as _Error, Roles, UserClaim, } from '../../../../../core';
import { Gender } from '../../../../../core/profile/gender';
import { Profile } from '../../../../../core/profile/profile';
import { FirebaseAuth, FirebaseFirestore, FirebaseFunctions, FirebaseStorage } from '../../app.config';
import { CookieService } from '../../core/cookie.service';
import { type Error, ErrorCode } from '../../core/error';
import { FirebaseUser, TokenResult } from '../../core/firebase/auth/auth.interface';
import { NotificationService } from '../../notification/notification.service';
import { AnalyticsService } from './analytics.service';

export type User = UserClaim & {
  uid: string;
  email: string;
  authCompleted: boolean;
  hasPasswordLogin: boolean;
};

@Injectable({
  providedIn: 'root',
})
/**
 * Auth service
 */
export class AuthService {
  initAuthProgress = signal(false);
  user = signal<User | null>({
    additionalAddress: "",
    address: "",
    admin: false,
    birthDate: "",
    centerErpId: 0,
    city: "",
    country: "",
    email: '',
    erpId: 0,
    firstName: "",
    gender: Gender.Male,
    hasPasswordLogin: false,
    lastName: "",
    notification: [],
    phone: "",
    photoURL: "",
    roles: [],
    shouldUpdateCenter: false,
    uid: '',
    zip: "",
    authCompleted: true
  });
  profileCompleted = computed(() => {
    const user = this.user();
    return !!(user && user.firstName && user.lastName && user.birthDate
      && user.address && user.zip && user.city && user.country && user.centerErpId && !user.shouldUpdateCenter);
  });
  private firestore = inject(FirebaseFirestore);
  private platformId = inject(PLATFORM_ID);
  private cookieService = inject(CookieService);
  private auth = inject(FirebaseAuth);
  private functions = inject(FirebaseFunctions);
  private storage = inject(FirebaseStorage);
  private router = inject(Router);
  private notification = inject(NotificationService);
  private analyticsService = inject(AnalyticsService);
  private firstSnapShot: boolean = true;

  /**
   * Set up listener
   * @returns A Promise
   */
  async init(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      await this.auth.getRedirectResult();
      this.auth.onIdTokenChanged(user => this.onIdTokenChanged(user));
      // TODO: CHECK ZoneLess
      this.getIdToken(true)
        .catch((err: Error) => this.notification.open({
          type: 'error',
          title: 'error',
          message: err.message
        }));
      setInterval(() => this.getIdToken(true), 50 * 60 * 1000)
      while (!this.user()) {
        await new Promise(resolve => setTimeout(() => resolve(null), 100));
      }
    }
  }

  /**
   * Determines whether a user has a specific role.
   * @param role The role to check.
   * @param exact If true, User must have this specifically, ignoring admin flag
   * @returns True if the user has the specified role, otherwise false.
   */
  hasRole(role: Roles, exact: boolean = false): boolean {
    const user = this.user();
    if (!user) {
      return false;
    }
    if (!exact && user.admin) {
      return true;
    }
    return user.roles?.includes(role);
  }

  /**
   * Sign up the user
   * @param email The desired email
   * @param password The desired password
   * @returns The promise
   */
  async signUp(email: string, password: string): Promise<void> {
    try {
      await this.auth.linkWithEmailAndPassword(email, password);
      this.analyticsService.signup('mail');
    } catch (err) {
      await this.auth.signOut();
      const { message, code } = err as {
        message: string,
        code: ErrorCode
      };
      let error = {
        message,
        details: { code },
      };
      // Auth Blocking Functions return our error serialized in the message
      if (code === 'auth/internal-error') {
        error = JSON.parse(/(?<error>{.*})/.exec(message)?.groups?.['error'] ?? 'null')?.['error'];
      }
      if (error.details.code === 'auth/email-not-verified') {
        return;
      }
      throw error;
    }
  }

  /**
   * Call API to update user profile in Firebase and Aquao
   * @param profile The user profile
   * @returns The promise
   */
  async updateProfile(profile: Profile): Promise<void> {
    const user = this.user();
    if (!user) {
      return;
    }
    await this.functions.httpsCallable<{
      uid: string
    } & Profile, void>('auth-updateProfile', {
      uid: user.uid,
      ...profile,
      phone: profile.phone?.replace(/^0/, '+33')
    });
    this.user.update(user => {
      if (!user) {
        return null;
      }
      return { ...user, ...profile };
    });
  }

  /**
   * Email user to confirm its email address
   * @param email The email to verify
   * @returns The promise
   */
  async sendConfirmEmail(email: string): Promise<void> {
    await this.functions.httpsCallable<{
      email: string
    }, void>('auth-sendConfirmEmail', { email });
    this.notification.open({ type: 'message', title: 'verifyEmail', message: 'checkInbox' });
  }

  /**
   * Reset the password of the account linked to the email
   * @param email The email
   * @returns The promise
   */
  resetPassword(email: string): Promise<void> {
    return this.functions.httpsCallable<{
      email: string
    }, void>('auth-sendResetPasswordEmail', { email });
  }

  confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    return this.auth.confirmPasswordReset(oobCode, newPassword)
  }

  getIdToken(forceRefresh?: boolean): Promise<string | null> {
    return this.auth.getIdToken(forceRefresh)
  }

  async verifyPasswordResetCode(oobCode: string): Promise<string> {
    return this.auth.verifyPasswordResetCode(oobCode)
  }

  checkActionCode(oobCode: string): Promise<string | null> {
    return this.auth.checkActionCode(oobCode)
  }

  applyActionCode(oobCode: string): Promise<void> {
    return this.auth.applyActionCode(oobCode)
  }

  /**
   * Updates the user roles
   * @param uid The user id
   * @param roles The desired roles
   * @returns The promise
   */
  updateRoles(uid: string, roles: Roles[]): Promise<void> {
    return this.functions.httpsCallable<{
      uid: string,
      roles: object
    }, void>('auth-updateRoles', { uid, roles })
  }

  /**
   * Updates the user email
   * @param newEmail The desired new email
   * @returns The promise
   */
  updateEmail(newEmail: string): Promise<void> {
    return this.functions.httpsCallable<{
      email: string,
      newEmail: string
    }, void>('auth-sendUpdateEmail', { email: this.user()!.email, newEmail });
  }

  /**
   * Sign in the user with email and password
   * @param email The email
   * @param password The password
   * @returns The promise
   */
  async signIn(email: string, password: string): Promise<boolean> {
    try {
      this.initAuthProgress.set(true);
      const res = await this.functions.httpsCallable<{
        email: string,
        password: string
      }, boolean>('auth-signIn', { email, password });
      if (res) {
        return true;
      }
    } catch (err) {
      this.initAuthProgress.set(false);
      throw err;
    }
    try {
      await this.auth.signInWithEmailAndPassword(email, password);
    } catch (err) {
      this.initAuthProgress.set(false);
      const { message, code } = err as {
        message: string,
        code: ErrorCode
      };
      let error = {
        message,
        details: { code },
      };
      // Auth Blocking Functions return our error serialized in the message
      if (code === _Error.AuthInternalError) {
        error = JSON.parse(/(?<error>{.*})/.exec(message)?.groups?.['error'] ?? 'null')?.['error'];
      }
      throw error;
    }
    return false;
  }

  /**
   * Google sign-in
   * @returns The promise
   */
  signInWithGoogle(): Promise<void> {
    this.initAuthProgress.set(true);
    return this.auth.signInWithGoogle();
  }

  /**
   * Facebook sign-in
   * @returns The promise
   */
  signInWithFacebook(): Promise<void> {
    this.initAuthProgress.set(true);
    return this.auth.signInWithFacebook();
  }

  /**
   * Apple sign-in
   * @returns The promise
   */
  signInWithApple(): Promise<void> {
    this.initAuthProgress.set(true);
    return this.auth.signInWithApple();
  }

  /**
   * Signing out the user by signing in anonymously
   * @returns The promise
   */
  async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
      await this.router.navigate([ '/' ]);
    } catch (err) {
      const { message, code } = err as {
        message: string,
        code: ErrorCode
      };
      let error = {
        message,
        details: { code },
      };
      // Auth Blocking Functions return our error serialized in the message
      if (code === 'auth/internal-error') {
        error = JSON.parse(/(?<error>{.*})/.exec(message)?.groups?.['error'] ?? 'null')?.['error'];
      }
      throw error;
    }
  }

  /**
   * Save new profile image into firebase storage
   * @param content The file to upload
   * @returns Uploaded file URL
   */
  async updateAvatar(content: Blob): Promise<string> {
    const user = this.user();
    if (!user) {
      throw new Error('Cannot update avatar of unknown user');
    }
    const path = `user/${user.uid}/profile`;
    await this.storage.upload(path, content);
    return this.storage.url(path);
  }

  private unsubscribeUser: () => void = () => {
  };

  /**
   * The local user id token change
   * @param user The logged-in user
   */
  private async onIdTokenChanged(user: FirebaseUser): Promise<void> {
    if (!user) {
      await this.auth.signInAnonymously();
      return;
    }
    this.initAuthProgress.set(false);
    const token = (await this.getIdToken())!;
    await this.updateUserInfo(token, user);
    if (isPlatformBrowser(this.platformId)) {
      await this.getUserData(user.uid);
    }
    this.cookieService.setAuth(token);
    if (this.user()?.authCompleted) {
      this.analyticsService.login(user.providerId);
    }
  }

  /**
   * Set local user info from auth token
   * @param token The auth token
   * @param user The logged-in user
   */
  private async updateUserInfo(token: string, user: FirebaseUser): Promise<void> {
    const parsedToken = jwtDecode<TokenResult>(token);
    const providerData = user?.providerData
      .find(p => p.providerId === parsedToken.firebase?.sign_in_provider);
    const newUser = {
      uid: user!.uid,
      email: user!.email ?? '',
      authCompleted: !user!.isAnonymous && user!.emailVerified,
      phone: parsedToken.phone?.replace('+33', '0') ?? '',
      firstName: parsedToken.firstName ?? providerData?.displayName?.split(' ')[0] ?? '',
      lastName: parsedToken.lastName ?? providerData?.displayName?.split(' ')[1] ?? '',
      photoURL: parsedToken['picture'] as string ?? (providerData as WebAuthUser)?.photoURL ??
        (providerData as NativeAuthUser)?.photoUrl ?? '',
      address: parsedToken.address ?? '',
      additionalAddress: parsedToken.additionalAddress ?? '',
      zip: parsedToken.zip ?? '',
      city: parsedToken.city ?? '',
      country: parsedToken.country ?? '',
      birthDate: parsedToken.birthDate ?? '1970-01-01',
      gender: parsedToken.gender ?? Gender.Male,
      notification: parsedToken.notification ?? [],
      admin: parsedToken.admin ?? false,
      roles: parsedToken.roles ?? [],
      erpId: parsedToken.erpId,
      hasPasswordLogin: !!user!.providerData.find(p => p.providerId === 'password'),
      centerErpId: parsedToken.centerErpId,
      shouldUpdateCenter: parsedToken.shouldUpdateCenter
    }
    if (JSON.stringify(newUser) !== JSON.stringify(this.user())) {
      this.user.set(newUser);
    }
  }

  /**
   * Get user data from Firestore
   * @param uid The user id
   */
  private async getUserData(uid: string): Promise<void> {
    this.unsubscribeUser();
    this.firstSnapShot = true;
    this.unsubscribeUser = await this.firestore.onSnapshotDocument(`user/${uid}`, () => {
      if (this.firstSnapShot) {
        this.firstSnapShot = false;
        return;
      }
      this.getIdToken(true)
    });
  }
}
