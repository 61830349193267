import {CartItem, CartItemType, GiftCartItem, StandardCartItem} from './cart/cart-item.model';

export type OrderState =
    'created'
    | 'billed'
    | 'committed'
    | 'paid'
    | 'invoiced'
    | 'registered'
    | 'consumed'
    | 'processed'
    | 'error';

export type OrderMail = {
    toSend: boolean,
    sent: boolean,
    oid: number,
    to: string,
    type: 'session' | 'timeCredit' | 'voucher' | 'card' | 'ticket',
    toRecipient: boolean
}

export class Order {
    id: string;
    items: CartItem[];
    timestamp: Date;
    state: OrderState;
    mails: OrderMail[];
    invoices: { oid: number, code: string }[];
    promoCodes: { code: string, discount: number }[];
    giftCardCodes: { code: string, discount: number, lost: number }[];
    reference: string;
    center: string;
    email: string;
    user: string;
    price: number;
    taxIncluded: number;
    tax: number;
    erpId: string;
    retry: string;

    constructor(order?: Partial<Order>) {
        this.items = order?.items?.map(i => {
            switch (i.type) {
                case CartItemType.Standard:
                    return new StandardCartItem(i);
                case CartItemType.Gift:
                    return new GiftCartItem(i);
            }
        }) ?? [];
        this.id = order?.id ?? '';
        this.retry = order?.retry ?? '';
        this.center = order?.center ?? '';
        this.email = order?.email ?? '';
        this.user = order?.user ?? '';
        this.price = order?.price ?? -1;
        this.taxIncluded = order?.taxIncluded ?? -1;
        this.tax = order?.tax ?? -1;
        this.timestamp = order?.timestamp ?? new Date();
        this.promoCodes = order?.promoCodes ?? [];
        this.giftCardCodes = order?.giftCardCodes ?? [];
        this.reference = order?.reference ?? '';
        this.erpId = order?.erpId ?? '';
        this.state = order?.state ?? 'billed';
        this.mails = order?.mails ?? [];
        this.invoices = order?.invoices ?? [];
    }
}
