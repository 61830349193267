import {MediaDocument} from './media-document.model';

/**
 * Page Model
 */
export class PageNotification {
    id: string;
    index: number;
    target: string[];
    title: string;
    image: MediaDocument;
    content: string;
    color: string;
    start: string;
    end: string;
    center: (string | 'landing')[];

    /**
     * Construct page from a partial page object
     * @param pageNotification A Page like object
     */
    constructor(pageNotification?: Partial<PageNotification>) {
        this.id = pageNotification?.id ?? '';
        this.index = pageNotification?.index ?? 0;
        this.target = pageNotification?.target ?? ['/'];
        this.title = pageNotification?.title ?? '';
        this.image = new MediaDocument(pageNotification?.image);
        this.content = pageNotification?.content ?? '';
        this.color = pageNotification?.color ?? '#36365F';
        this.start = pageNotification?.start ?? '';
        this.end = pageNotification?.end ?? '';
        this.center = pageNotification?.center ?? ['landing'];
    }
}
