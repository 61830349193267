import { ConsentStatus, ConsentType, FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseAnalyticsInterface } from './analytics.interface';

export class FirebaseAnalyticsNative implements FirebaseAnalyticsInterface {

  constructor() {
    FirebaseAnalytics.setConsent({ type: ConsentType.AdPersonalization, status: ConsentStatus.Granted });
    FirebaseAnalytics.setConsent({ type: ConsentType.AdStorage, status: ConsentStatus.Granted });
    FirebaseAnalytics.setConsent({ type: ConsentType.AdUserData, status: ConsentStatus.Granted });
    FirebaseAnalytics.setConsent({ type: ConsentType.AnalyticsStorage, status: ConsentStatus.Granted });
    FirebaseAnalytics.setConsent({ type: ConsentType.FunctionalityStorage, status: ConsentStatus.Granted });
    FirebaseAnalytics.setConsent({ type: ConsentType.PersonalizationStorage, status: ConsentStatus.Granted });
  }

  async logEvent(eventName: string, eventParams: Record<string, unknown>): Promise<void> {
    await FirebaseAnalytics.logEvent({
      name: eventName,
      params: eventParams
    });
  }
}
