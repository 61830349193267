import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Center as _Center} from '../../../../../core/model/center.model'

/**
 * Center Model
 */
export class Center extends _Center {
  static toFirestore(): DocumentData {
    throw new Error('Read only');
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Center {
    const data = snapshot.data(options);
    return new Center({
      ...data,
      id: snapshot.id,
      description: '',
      openingHours: '',
      infoBaths: '',
      infoSpa: '',
      infoPlanning: '',
      infoMustTake: '',
      infoWhoAllowed: '',
      products: (data['products'] ?? []).map((p: object) => ({
        ...p,
        longDescription: '',
        longDescription2: '',
        goodToKnow: '',
        bePrepared: '',
        access: ''
      }))
    });
  }
}
